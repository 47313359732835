import { Box, Button, Tooltip, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconCirclePlus } from '@tabler/icons-react';
import React, { MouseEventHandler } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRouteURL } from '../../AppRouteURL';
import { isProfileEmpty } from '../../models/ProfileQueries';
import { useQueryClient } from '@tanstack/react-query';
import { QUERY_KEY_RESUMES } from '../../queries/ResumeQueries';

import classes from './CreateResumeButton.module.css';

export const CreateResumeButton: React.FC<{
  onClick?: MouseEventHandler | undefined;
}> = ({ onClick }) => {
  const profileEmpty = isProfileEmpty();
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const isMediumScreen = useMediaQuery(
    `(min-width: ${theme.breakpoints.sm}) and (max-width: ${theme.breakpoints.md})`,
  );

  const handleClick: MouseEventHandler = (e) => {
    // Clear any existing resume data from the cache
    void queryClient.invalidateQueries({ queryKey: QUERY_KEY_RESUMES });

    // Navigate to create page
    navigate(AppRouteURL.resumes.create);

    // Call the original onClick if provided
    onClick?.(e);
  };

  return (
    <Box>
      <Tooltip
        label={
          profileEmpty ? (
            <Trans i18nKey="applications.missingProfileInfo">
              You need to
              <span>update your profile</span>
              before you can start creating applications!
            </Trans>
          ) : (
            t('applications.newApplication')
          )
        }
      >
        <Button
          onClick={handleClick}
          leftSection={<IconCirclePlus />}
          classNames={{ root: classes.createResumeButton }}
        >
          {isMediumScreen ? t('labels.new') : t('applications.newApplication')}
        </Button>
      </Tooltip>
    </Box>
  );
};
