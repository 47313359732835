import { LANDING_PAGE_URL } from './Config';

export const AppRouteURL = {
  home: '/',
  login: '/login',
  signup: '/signup',
  admin: '/admin',
  adminUser: '/admin/user',
  feedback: '/feedback',
  resumes: {
    index: '/resumes',
    create: '/resumes/create',
    list: '/resumes/list',
    view: '/resumes/view',
    newApplication: '/resumes/new',
  },
  profile: '/user/profile',
  profileTabBasic: '/user/profile/basic',
  profileTabIntro: '/user/profile/intro',
  profileTabTimeline: '/user/profile/timeline',
  profileImport: '/users/profile/import',
  account: '/user/account',
  subscribe: '/user/account#subscribe',
  openingSoon: '/openingSoon',
  verifyEmail: '/verify-email',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  checkoutSuccess: '/checkout/success',
  checkoutCanceled: '/checkout/canceled',
  about: '/about',
  terms: '/terms',
  cookiePolicy: '/cookie-policy',
  privacyPolicy: '/privacy',
  disclaimer: '/disclaimer',
  acceptableUse: '/acceptable-use',
  linkedInCallback: '/linkedin/callback',
  analyze: '/analyze',
  achievements: '/achievements',
};

export const ExternalURL = {
  terms: `${LANDING_PAGE_URL}/terms`,
  privacyPolicy: `${LANDING_PAGE_URL}/privacy`,
  imprint: `${LANDING_PAGE_URL}/imprint`,
};
