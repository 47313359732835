import { Container, Paper, Stack } from '@mantine/core';
import React from 'react';
import { WizardProvider, useWizard } from './context/WizardContext';
import { JobInputStep } from './steps/JobInputStep';
import { PreviewStep } from './steps/PreviewStep';
import { PreferencesStep } from './steps/PreferencesStep';
import { InstructionsStep } from './steps/InstructionsStep';
import { GenerationStep } from './steps/GenerationStep';

const WizardContent: React.FC = () => {
  const { currentStep } = useWizard();

  const renderStep = () => {
    switch (currentStep) {
      case 'input':
        return <JobInputStep />;
      case 'preview':
        return <PreviewStep />;
      case 'preferences':
        return <PreferencesStep />;
      case 'instructions':
        return <InstructionsStep />;
      case 'generation':
        return <GenerationStep />;
      default:
        return null;
    }
  };

  return (
    <Container size="lg" py="xl">
      <Paper
        shadow="md"
        p="xl"
        radius="lg"
        style={{
          position: 'relative',
          minHeight: '60vh',
        }}
      >
        <Stack>{renderStep()}</Stack>
      </Paper>
    </Container>
  );
};

export const NewApplicationWizard: React.FC = () => {
  return (
    <WizardProvider>
      <WizardContent />
    </WizardProvider>
  );
};
