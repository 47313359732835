import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';

interface AnimatedInputWrapperProps {
  children: React.ReactElement;
}

const inputAnimation = {
  initial: { scale: 1 },
  focus: {
    scale: 1.02,
    transition: {
      type: 'spring',
      stiffness: 500,
      damping: 15,
    },
  },
};

export const AnimatedInputWrapper: React.FC<AnimatedInputWrapperProps> = ({
  children,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const input = inputRef.current?.querySelector('input');
    if (!input) return;

    const handleFocus = () => {
      setIsFocused(true);
    };
    const handleBlur = () => {
      setIsFocused(false);
    };

    input.addEventListener('focus', handleFocus);
    input.addEventListener('blur', handleBlur);

    return () => {
      input.removeEventListener('focus', handleFocus);
      input.removeEventListener('blur', handleBlur);
    };
  }, []);

  return (
    <motion.div
      ref={inputRef}
      animate={isFocused ? 'focus' : 'initial'}
      variants={inputAnimation}
    >
      {children}
    </motion.div>
  );
};
