import { Paper, Title, Group, ThemeIcon, Text, Stack } from '@mantine/core';
import { IconCrown } from '@tabler/icons-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRouteURL } from '../../AppRouteURL';
import { SubscriptionType, useUserQuery } from '../../models/User';
import { motion } from 'framer-motion';

import classes from './HomeCard.module.css';

const SubscribeCard: React.FC = () => {
  const { data: user } = useUserQuery();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <motion.div
      layout
      style={{ height: '100%' }}
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
    >
      <Paper
        shadow="lg"
        radius="xl"
        p="xl"
        onClick={() => {
          void navigate(
            user?.subscriptionType === SubscriptionType.PRO
              ? AppRouteURL.account
              : AppRouteURL.subscribe,
          );
        }}
        className={classes.homeCard}
      >
        <div className={classes.homeCardWrapper}>
          <Group justify="space-between" align="center">
            <Title order={5}>{t('header.subscription')}</Title>
            <ThemeIcon
              size="lg"
              radius="xl"
              variant="gradient"
              gradient={{ from: 'yellow', to: 'orange' }}
            >
              <IconCrown style={{ width: '70%', height: '70%' }} />
            </ThemeIcon>
          </Group>

          <div className={classes.homeCardContent}>
            <Stack gap="xs" align="flex-start">
              <Group align="center" gap="xs">
                <Text fw={700} size="xl">
                  {t('subscription.currentPlan')}
                </Text>
                <Text
                  fw={700}
                  size="xl"
                  variant="gradient"
                  gradient={{ from: 'teal', to: 'lime', deg: 105 }}
                >
                  {user?.subscriptionType === SubscriptionType.PRO
                    ? t('subscription.pro')
                    : t('subscription.free')}
                </Text>
              </Group>
              <Text size="md" c="dimmed">
                {user?.subscriptionType === SubscriptionType.PRO
                  ? t('subscription.proDescription')
                  : t('subscription.freeDescription')}
              </Text>
              {user?.subscriptionType === SubscriptionType.PRO &&
                user.subscriptionExpiresAt && (
                  <Text size="sm" c="dimmed">
                    {t('subscription.proEndsAt', {
                      expiry: new Date(
                        user.subscriptionExpiresAt,
                      ).toLocaleDateString(),
                    })}
                  </Text>
                )}
            </Stack>
          </div>
        </div>
      </Paper>
    </motion.div>
  );
};

export default SubscribeCard;
