export const enum ApiRoutes {
  Login = 'auth/login',
  Logout = 'auth/logout',
  Signup = 'auth/register',
  Refresh = 'auth/refresh',
  Me = 'users/me',
  Timeline = 'users/me/timeline',
  Tours = 'users/me/tours',
  Resumes = 'resumes',
  NewSuggestion = 'new-suggestion',
  Profile = 'users/profile',
  ProfileImport = 'users/profile/import',
  VerifyEmail = 'users/verify-email',
  ResetPassword = 'users/reset-password',
  Feedback = 'feedback',
  Checkout = 'stripe/create-checkout-session',
  TemplateTypes = 'resumes/template-types',
  Subscription = 'subscriptions',
  TransactionsApple = 'subscriptions/transactions/apple',
  ProductIdsApple = 'subscriptions/products/apple',
  Prices = 'subscriptions/prices',
  SignInWithGoogleCallback = 'auth/google/callback',
  SigninWithLinkedIn = 'auth/linkedin',
  SigninWithLinkedInCallback = 'auth/linkedin/callback',
  SignInWithApple = 'auth/apple',
  Users = 'users',
  Analysis = 'analysis',
  AnalysisImport = 'analysis/import',
  ApnsTokens = 'notifications/apns-tokens',
  Interviews = 'interviews',
  Achievements = 'achievements',
  UserAchievements = 'achievements/my',
  Suggestions = 'suggestions',
}
