import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  JobAdType,
  ResumeStatus,
  ResumeStyles,
  ResumeLanguageCode,
} from '../../../models/Resume';
import {
  useCreateResumeMutation,
  useResumeQuery,
  useParseResumeMutation,
  useUpdateResumeMutation,
  useGenerateResumeMutation,
} from '../../../queries/ResumeQueries';
import { WizardContextType, WizardState, WizardStep } from '../types';
import { getLogger } from '../../../services/Logger';

const logger = getLogger('WizardContext');

const initialState: WizardState = {
  jobInput: {
    type: null,
    content: '',
  },
  preferences: {},
  generation: {
    status: ResumeStatus.New,
  },
};

const WizardContext = createContext<WizardContextType | undefined>(undefined);

export const WizardProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { i18n } = useTranslation();
  const resumeId = searchParams.get('resumeId');
  const step = (searchParams.get('step') as WizardStep) || 'input';
  const { data: existingResume } = useResumeQuery(resumeId || undefined);

  // Get the current UI language, defaulting to 'en' if not supported
  const getCurrentLanguage = useCallback((): ResumeLanguageCode => {
    const lang = i18n.languages[0].split('-')[0]; // Get primary language, handle cases like 'en-US'
    switch (lang) {
      case 'de':
        return 'de';
      case 'fr':
        return 'fr';
      case 'es':
        return 'es';
      case 'it':
        return 'it';
      default:
        return 'en';
    }
  }, [i18n.languages]);

  const [state, setState] = useState<WizardState>({
    ...initialState,
    preferences: {
      language: getCurrentLanguage(),
    },
  });

  const createResumeMutation = useCreateResumeMutation();
  const parseResumeMutation = useParseResumeMutation();
  const updateResumeMutation = useUpdateResumeMutation();
  const generateResumeMutation = useGenerateResumeMutation();

  // Initialize state from existing resume
  useEffect(() => {
    if (existingResume) {
      setState((prev) => ({
        ...prev,
        jobInput: {
          type: existingResume.jobAdType || null,
          content: existingResume.jobAdUrl || existingResume.ad || '',
        },
        preferences: {
          language: existingResume.language || getCurrentLanguage(),
          style: existingResume.style as (typeof ResumeStyles)[number],
          instructions: existingResume.info,
        },
        generation: {
          status: existingResume.status,
          resumeId: existingResume.id,
          error:
            existingResume.status === ResumeStatus.InvalidURL ||
            existingResume.status === ResumeStatus.Error
              ? 'Failed to parse website'
              : undefined,
        },
      }));

      // Force step changes for specific states, but respect manual navigation to input
      const currentStep = searchParams.get('step') as WizardStep;
      if (currentStep === 'input') {
        // Don't force step change if we're intentionally on input step
        return;
      }

      if (
        existingResume.status === ResumeStatus.InvalidURL ||
        existingResume.status === ResumeStatus.Error
      ) {
        setSearchParams({ step: 'preview', resumeId: existingResume.id });
      } else if (existingResume.status === ResumeStatus.Generating) {
        setSearchParams({ step: 'generation', resumeId: existingResume.id });
      } else if (existingResume.status === ResumeStatus.Preparing) {
        // For preparing state, always show preview with loading
        setSearchParams({ step: 'preview', resumeId: existingResume.id });
      } else if (
        existingResume.status === ResumeStatus.WebsiteParsed &&
        !searchParams.get('step')
      ) {
        // If no step is set and we have a parsed website, show preview
        setSearchParams({ step: 'preview', resumeId: existingResume.id });
      }
      // For other cases, respect the current step in the URL
    }
  }, [existingResume, setSearchParams, getCurrentLanguage, searchParams]);

  // Update preferences when UI language changes
  useEffect(() => {
    setState((prev) => ({
      ...prev,
      preferences: {
        ...prev.preferences,
        language: prev.preferences.language || getCurrentLanguage(),
      },
    }));
  }, [getCurrentLanguage]);

  const setJobInput = useCallback((type: JobAdType | null, content: string) => {
    setState((prev) => ({
      ...prev,
      jobInput: { type, content },
      // Reset preview when input changes
      jobPreview: undefined,
    }));
  }, []);

  const scanWebsite = useCallback(async () => {
    try {
      // First create a new resume with the URL
      const resume = await createResumeMutation.mutateAsync({
        jobAdType: JobAdType.URL,
        jobAdUrl: state.jobInput.content,
      });

      // Then trigger the parsing
      try {
        await parseResumeMutation.mutateAsync(resume.id);

        setState((prev) => ({
          ...prev,
          generation: {
            status: ResumeStatus.WebsiteParsed,
            resumeId: resume.id,
          },
        }));
      } catch (parseError) {
        // If parsing fails, keep the resume but mark it as invalid URL
        setState((prev) => ({
          ...prev,
          generation: {
            status: ResumeStatus.InvalidURL,
            resumeId: resume.id,
            error:
              parseError instanceof Error
                ? parseError.message
                : 'Failed to parse website',
          },
        }));
      }
      // In both success and parse error cases, we want to show the preview with the resumeId
      setSearchParams({ step: 'preview', resumeId: resume.id });
    } catch (error) {
      // Only if resume creation fails, we're in a completely error state
      setState((prev) => ({
        ...prev,
        generation: {
          status: ResumeStatus.Error,
          error:
            error instanceof Error ? error.message : 'Failed to create resume',
        },
      }));
      setSearchParams({ step: 'preview' });
    }
  }, [
    state.jobInput.content,
    createResumeMutation,
    parseResumeMutation,
    setSearchParams,
  ]);

  const confirmJobAd = useCallback(() => {
    const params: Record<string, string> = { step: 'preferences' };
    if (state.generation.resumeId) {
      params.resumeId = state.generation.resumeId;
    }
    setSearchParams(params);
  }, [setSearchParams, state.generation.resumeId]);

  const setPreferences = useCallback(
    (prefs: Partial<WizardState['preferences']>) => {
      setState((prev) => ({
        ...prev,
        preferences: { ...prev.preferences, ...prefs },
      }));
    },
    [],
  );

  const startGeneration = useCallback(async () => {
    setState((prev) => ({
      ...prev,
      generation: { ...prev.generation, status: ResumeStatus.Generating },
    }));

    try {
      let resumeId = state.generation.resumeId;

      // If we don't have a resumeId (text input), create the resume first
      if (!resumeId) {
        if (!state.jobInput.type) {
          logger.error('Attempted to start generation without job input type');
          throw new Error('No job input type selected');
        }
        const resume = await createResumeMutation.mutateAsync({
          jobAdType: state.jobInput.type,
          ad: state.jobInput.content,
          language: state.preferences.language || getCurrentLanguage(),
          style: state.preferences.style,
          info: state.preferences.instructions,
        });
        resumeId = resume.id;
      } else {
        // Update existing resume with preferences
        await updateResumeMutation.mutateAsync({
          id: resumeId,
          data: {
            language: state.preferences.language || getCurrentLanguage(),
            style: state.preferences.style,
            info: state.preferences.instructions,
          },
        });
      }

      // Start generation
      await generateResumeMutation.mutateAsync(resumeId);

      setState((prev) => ({
        ...prev,
        generation: {
          status: ResumeStatus.Generating,
          resumeId,
        },
      }));
      setSearchParams({ step: 'generation', resumeId });
    } catch (error) {
      setState((prev) => ({
        ...prev,
        generation: {
          status: ResumeStatus.Error,
          error:
            error instanceof Error
              ? error.message
              : 'Failed to generate resume',
        },
      }));
    }
  }, [
    state,
    createResumeMutation,
    updateResumeMutation,
    generateResumeMutation,
    setSearchParams,
    getCurrentLanguage,
  ]);

  const goToStep = useCallback(
    (nextStep: WizardStep) => {
      // Handle navigation based on resume type and current state
      const isTextBasedResume = state.jobInput.type === JobAdType.Text;

      // When going back to input, reset state appropriately
      if (nextStep === 'input') {
        if (
          state.generation.status === ResumeStatus.InvalidURL ||
          state.generation.status === ResumeStatus.Error
        ) {
          setState((prev) => ({
            ...prev,
            jobInput: {
              type: null,
              content: '',
            },
            generation: {
              status: ResumeStatus.New,
            },
          }));
          setSearchParams({ step: 'input' });
          return;
        }

        // For text-based resumes, clear resumeId when going back to input
        if (isTextBasedResume) {
          setState((prev) => ({
            ...prev,
            generation: {
              status: ResumeStatus.New,
            },
          }));
          setSearchParams({ step: 'input' });
          return;
        }
      }

      // For text-based resumes, only allow navigation between input, preferences, and generation
      if (isTextBasedResume) {
        if (nextStep === 'preview') {
          // If trying to go to preview, stay on current step
          return;
        }
        setSearchParams({ step: nextStep });
        return;
      }

      // Handle preview step for URL-based resumes
      if (nextStep === 'preview') {
        if (!state.generation.resumeId) {
          // If no resumeId exists, go back to input
          setSearchParams({ step: 'input' });
          return;
        }
      }

      // Normal navigation with resumeId if it exists
      const params: Record<string, string> = { step: nextStep };
      if (state.generation.resumeId && !isTextBasedResume) {
        params.resumeId = state.generation.resumeId;
      }
      setSearchParams(params);
    },
    [
      setSearchParams,
      state.generation.resumeId,
      state.generation.status,
      state.jobInput.type,
      setState,
    ],
  );

  // Listen for URL changes to handle back button
  useEffect(() => {
    // If we're at the input step without params, reset the state
    if (step === 'input' && !resumeId && !searchParams.get('error')) {
      setState((prev) => ({
        ...prev,
        generation: {
          status: ResumeStatus.New,
        },
        jobPreview: undefined,
      }));
    }
  }, [step, resumeId, searchParams, setState]);

  const contextValue = useMemo(
    () => ({
      state,
      currentStep: step,
      actions: {
        setJobInput,
        scanWebsite,
        confirmJobAd,
        setPreferences,
        startGeneration,
        goToStep,
      },
    }),
    [
      state,
      step,
      setJobInput,
      scanWebsite,
      confirmJobAd,
      setPreferences,
      startGeneration,
      goToStep,
    ],
  );

  return (
    <WizardContext.Provider value={contextValue}>
      {children}
    </WizardContext.Provider>
  );
};

export const useWizard = () => {
  const context = useContext(WizardContext);
  if (!context) {
    throw new Error('useWizard must be used within a WizardProvider');
  }
  return context;
};
