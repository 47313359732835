import {
  ActionIcon,
  Anchor,
  Group,
  Menu,
  Stack,
  Table,
  Text,
  Title,
  Tooltip,
  rem,
} from '@mantine/core';
import { IconEye, IconTrash } from '@tabler/icons-react';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AppRouteURL } from '../../AppRouteURL';
import { DeleteResumeButton } from '../../components/DeleteResumeButton/DeleteResumeButton';
import { IResume, ResumeStatus, splitTitleText } from '../../models/Resume';
import { ResumeStatusIcon } from './ResumeStatusIcon';
import { ResumeTitleText } from './ResumeTitleText';

const viewUrl = (id: string) => AppRouteURL.resumes.view + '/' + id;
const createUrl = (id: string) =>
  `${AppRouteURL.resumes.create}?resumeId=${id}`;
const generatingUrl = (id: string) =>
  `${AppRouteURL.resumes.create}?step=generation&resumeId=${id}`;
const resumeLink = (id: string, status: ResumeStatus) => {
  switch (status) {
    case ResumeStatus.New:
    case ResumeStatus.Error:
    case ResumeStatus.InvalidURL:
      return createUrl(id);
    case ResumeStatus.WebsiteParsed:
      return `${AppRouteURL.resumes.create}?step=preview&resumeId=${id}`;
    case ResumeStatus.Preparing:
    case ResumeStatus.Generating:
    case ResumeStatus.CoverLetter:
      return generatingUrl(id);
    case ResumeStatus.Ok:
      return viewUrl(id);
  }
};

export const ResumeTableRow: React.FC<{
  resume: IResume;
}> = ({ resume }) => {
  const { t } = useTranslation();
  const { label, description } = splitTitleText(resume);

  return (
    <Table.Tr>
      <Table.Td>
        <Anchor
          component={Link}
          to={resumeLink(resume.id, resume.status)}
          variant="text"
          c="var(--mantine-color-text)"
          underline="hover"
          style={{ display: 'block', padding: `${rem(4)} 0` }}
        >
          <Group gap="sm" wrap="nowrap">
            <ResumeStatusIcon status={resume.status} size={22} />
            <Stack gap={2}>
              <Title order={5} style={{ fontSize: rem(16), lineHeight: 1.3 }}>
                {label || <ResumeTitleText resume={resume} />}
              </Title>
              {description && (
                <Text size="sm" c="dimmed" lineClamp={2}>
                  {description}
                </Text>
              )}
            </Stack>
          </Group>
        </Anchor>
      </Table.Td>
      <Table.Td>
        <Tooltip
          label={dayjs(resume.createdAt).format('LLLL')}
          position="bottom"
        >
          <Text size="sm" style={{ whiteSpace: 'nowrap' }}>
            {dayjs(resume.createdAt).fromNow()}
          </Text>
        </Tooltip>
      </Table.Td>
      <Table.Td>
        <Group gap="xs" justify="flex-end">
          <Tooltip label={t('applications.viewApplication')} position="left">
            <ActionIcon
              component={Link}
              to={viewUrl(resume.id)}
              variant="light"
              color="blue"
              size="lg"
              radius="md"
            >
              <IconEye style={{ width: rem(18), height: rem(18) }} />
            </ActionIcon>
          </Tooltip>
          <DeleteResumeButton resume={resume}>
            <ActionIcon variant="light" color="red" size="lg" radius="md">
              <IconTrash style={{ width: rem(18), height: rem(18) }} />
            </ActionIcon>
          </DeleteResumeButton>
        </Group>
      </Table.Td>
    </Table.Tr>
  );
};
