import {
  Alert,
  Anchor,
  Button,
  Center,
  Container,
  Group,
  Modal,
  Paper,
  rem,
  SegmentedControl,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { IconCreditCard, IconStar } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Api from '../../Api';
import { ApiRoutes } from '../../ApiRoutes';
import { AppRouteURL } from '../../AppRouteURL';
import SubscriptionInfo from '../../components/SubscriptionInfo/SubscriptionInfo';
import { PAYMENT_ENABLED } from '../../Config';
import { IProduct } from '../../models/Product';
import { isSubscriptionExpired, useUserQuery } from '../../models/User';
import { useProductQuery } from '../../queries/ProductQueries';
import { getLogger } from '../../services/Logger';
import Platform from '../../services/Platform';
import Tracking from '../../services/Tracking';
import SubscribeButton from './SubscribeButton';
import classes from './SubscribePopup.module.css';

const log = getLogger('SubscribePopup');

type Currency = 'eur' | 'usd';

const CurrencyLabels = [
  {
    label: 'Euro €',
    value: 'eur',
  },
  {
    label: 'US $',
    value: 'usd',
  },
] as { label: string; value: Currency }[];

function getPricingInterval(recurring: IProduct['recurring']) {
  if (recurring?.interval === 'day') {
    return recurring.interval_count === 7 ? 'week' : 'day';
  } else if (recurring?.interval === 'month') {
    return recurring.interval_count === 3 ? 'quarter' : 'month';
  }
  return recurring?.interval || 'year';
}

const PriceButton: React.FC<{
  product: IProduct;
  purchase: (product: IProduct) => Promise<void>;
  isRedirecting: boolean;
  currency: Currency;
  isExpired: boolean;
}> = ({ currency, product, purchase, isExpired, isRedirecting }) => {
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 50em)');

  const pricingInterval = getPricingInterval(product.recurring);
  const isQuarterly = pricingInterval === 'quarter';

  const centToCurrency = (value?: number | null) =>
    Intl.NumberFormat(i18n.languages[0], {
      currency,
      style: 'currency',
    }).format((typeof value === 'number' ? value : 0) / 100.0);

  return (
    <Button
      size="xl"
      variant="default"
      onClick={() => {
        void purchase(product);
      }}
      loading={isRedirecting}
      radius="xl"
      mih={rem(400)}
      p={rem(8)}
      className={classes.priceButton}
    >
       {isQuarterly && (
        <div className={classes.popularBanner}>{t('subscriptions.mostPopular')}</div>
      )}
      <Stack gap="lg" align="center" style={{ whiteSpace: 'normal' }}>
        <Text fz={{ base: 'md', md: 'xl' }} fw={700} c="blue">
          {t(`subscriptions.${pricingInterval}Title`)}
        </Text>

        <Group gap={0}>
          <Text fz={{ base: 24, md: 36 }} fw={600}>
            {centToCurrency(product.currencies[currency].unitAmount)}
          </Text>
          <Text size="sm" mt="lg">
            {t(`subscriptions.${pricingInterval}Per`)}
          </Text>
        </Group>

        <Text size="xs" c="dimmed" mx="sm">
          {t(
            `subscriptions.${pricingInterval}${isExpired ? 'Re' : ''}PriceSubtitle`,
            {
              price: centToCurrency(product.currencies[currency].unitAmount),
            },
          )}
        </Text>

        {pricingInterval === 'quarter' ? (
          <Group gap={4} justify="center">
            <IconStar color="orange" size={isMobile ? 0 : 14} />
            <Text size="sm" ta="center" fw="bolder">
              {t('subscriptions.perMonthUpsell', {
                amount: centToCurrency(
                  (product.currencies[currency].unitAmount || 0) / 3,
                ),
              })}
            </Text>
            <IconStar color="orange" size={isMobile ? 0 : 14} />
          </Group>
        ) : (
          <Text size="sm">&nbsp;</Text>
        )}

        <Stack gap={rem(8)} align="center">
          <Text fz={{ base: 'xs', md: 'sm' }}>
            {t(`subscriptions.${pricingInterval}Description1`)}
          </Text>
          <Text fz={{ base: 'xs', md: 'sm' }}>
            {t(`subscriptions.${pricingInterval}Description2`)}
          </Text>
          <Text fz={{ base: 'xs', md: 'sm' }}>
            {t(`subscriptions.${pricingInterval}Description3`)}
          </Text>
        </Stack>
      </Stack>
    </Button>
  );
};

export const SubscribePopup: React.FC = () => {
  const { data: user, refetch: refetchUser } = useUserQuery();
  const [opened, { open, close }] = useDisclosure(false);
  const isMobile = useMediaQuery('(max-width: 50em)');
  const [purchaseError, setPurchaseError] = useState<string | null>(null);

  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const [currentCurrency, setCurrentCurrency] = useState<Currency>(
    i18n.languages[0].startsWith('en') ? 'usd' : 'eur',
  );

  const [isRedirecting, setRedirecting] = useState(false);

  let products: IProduct[] | undefined;

  if (Platform.iosPwa) {
    products = Platform.getProducts();
  } else {
    const { data } = useProductQuery();
    products = data;
  }

  if (!PAYMENT_ENABLED) {
    return null;
  }

  const closeModal = () => {
    close();
    void navigate(window.location.pathname + window.location.search, {
      replace: true,
    });
  };

  useEffect(() => {
    if (location.hash === '#subscribe') {
      open();
    }
  }, [location]); // React to changes in location.hash

  const purchase = async (product: IProduct) => {
    setPurchaseError(null);
    setRedirecting(true);

    const value =
      (product.currencies[currentCurrency].unitAmount ?? 100.0) / 100.0;

    try {
      await Tracking.trackBeginCheckout({
        itemId: product.id,
        currency: currentCurrency,
        value,
      });

      if (Platform.iosPwa) {
        if (!user) {
          throw new Error('Cannot purchase without user');
        }
        await Platform.startPurchase(user.id, product.id);
        await refetchUser();

        closeModal();
      } else {
        const { sessionUrl } = await Api.ky
          .get(
            `${ApiRoutes.Checkout}?priceId=${product.id}&currency=${currentCurrency}`,
          )
          .json<{ sessionUrl: string }>();

        setRedirecting(false);

        window.location.href = sessionUrl;
      }
    } catch (error) {
      log.error(error);
      if (error instanceof Error || typeof error === 'string') {
        setPurchaseError(error.toString());
      } else {
        setPurchaseError('error');
      }
    } finally {
      setRedirecting(false);
    }
  };

  const hasMultipleCurrencies =
    products?.[0].currencies && Object.keys(products[0].currencies).length > 1;

  // FIXME: Workaround for Apple App Store review
  products?.forEach((p) => {
    if (p.recurring?.interval === 'day') {
      p.recurring.interval = 'week';
    }
  });

  const subDays = (product: IProduct) =>
    (product.recurring?.interval === 'day'
      ? 1
      : product.recurring?.interval === 'week'
        ? 7
        : product.recurring?.interval === 'month'
          ? 30
          : 365) * (product.recurring?.interval_count || 1);
  products?.sort((a, b) => subDays(a) - subDays(b));

  const errorComponent = purchaseError ? (
    <Alert
      title={t('translation:account.purchaseErrorTitle')}
      color="red"
      icon={<IconCreditCard />}
    >
      <Text>{t('translation:account.purchaseErrorText')}</Text>
    </Alert>
  ) : null;

  return (
    <>
      <Modal
        opened={opened}
        onClose={closeModal}
        title={
          <Text fz="lg" fw="bolder" mt={isMobile ? '2rem' : undefined}>
            {t('subscriptions.purchaseSubscription')}
          </Text>
        }
        fullScreen={isMobile}
        size="lg"
        transitionProps={{ transition: 'fade', duration: 200 }}
        centered
      >
        <Container p={{ base: 'xs', md: 'md' }}>
          <Stack gap="xl">
            <Text ta="center" size="md">
              {t(
                isSubscriptionExpired(user)
                  ? 'subscriptions.repurchaseSubscriptionText'
                  : 'subscriptions.purchaseSubscriptionText',
              )}
            </Text>

            <Group gap="md" grow>
              {products?.map((product) => (
                <PriceButton
                  key={product.id}
                  product={product}
                  purchase={purchase}
                  isRedirecting={isRedirecting}
                  currency={currentCurrency}
                  isExpired={isSubscriptionExpired(user)}
                />
              ))}
            </Group>

            {hasMultipleCurrencies && (
              <Center>
                <SegmentedControl
                  color="blue"
                  radius="lg"
                  data={CurrencyLabels}
                  value={currentCurrency}
                  onChange={(value) => {
                    setCurrentCurrency(value as Currency);
                  }}
                />
              </Center>
            )}

            <Stack gap="xs">
              <Text c="dimmed" size="xs" ta="center">
                {t(
                  `subscriptions.${isSubscriptionExpired(user) ? 're' : ''}subInfo1`,
                )}
              </Text>
              <Text c="dimmed" size="xs" ta="center">
                {t(
                  `subscriptions.${isSubscriptionExpired(user) ? 're' : ''}subInfo2`,
                )}
              </Text>
              <Text c="dimmed" size="xs" ta="center">
                <Trans i18nKey="subscriptions.subInfo3">
                  By subscribing, you agree to our{' '}
                  <Anchor href={AppRouteURL.terms}>Terms of Service</Anchor> and{' '}
                  <Anchor href={AppRouteURL.privacyPolicy}>
                    Privacy Policy
                  </Anchor>
                  .
                </Trans>
              </Text>
            </Stack>
            <Center>
              <Anchor onClick={closeModal} size="md" c="blue.3">
                {t('subscriptions.maybeLater')}
              </Anchor>
            </Center>
          </Stack>
        </Container>
      </Modal>
      <Stack>
        <Title order={3} mt="lg">
          {t('account.subscriptionTitle')}
        </Title>
        <Paper shadow="xl" radius="lg" withBorder>
          <SubscriptionInfo />
        </Paper>
        {errorComponent}
        <SubscribeButton
          onClick={() => {
            void navigate(AppRouteURL.subscribe);
          }}
        />
      </Stack>
    </>
  );
};
