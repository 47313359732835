import {
  Anchor,
  Button,
  Stack,
  Text,
  TextInput,
  PasswordInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconLock, IconMail } from '@tabler/icons-react';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence, MotionConfig } from 'framer-motion';
import { AppRouteURL } from '../../AppRouteURL';
import { useAuth } from '../../Auth';
import { LoginMethodsStack } from './LoginMethodsStack';
import { AnimatedInputWrapper } from '../AnimatedInputWrapper/AnimatedInputWrapper';
import Tracking from '../../services/Tracking';
import classes from './AuthForms.module.css';

interface SignupFormValues {
  email: string;
  password: string;
}

interface ApiError {
  response?: {
    status: number;
  };
  message?: string;
}

const formAnimation = {
  initial: { opacity: 0, height: 0, scale: 0.98 },
  animate: {
    opacity: 1,
    height: 'auto',
    scale: 1,
    transition: {
      height: {
        type: 'spring',
        stiffness: 200,
        damping: 10,
        duration: 0.3,
      },
      scale: {
        type: 'spring',
        stiffness: 400,
        damping: 25,
      },
      opacity: {
        duration: 0.15,
      },
      when: 'beforeChildren',
      staggerChildren: 0.05,
      delayChildren: 0.05,
    },
  },
  exit: {
    opacity: 0,
    height: 0,
    scale: 0.98,
    transition: {
      height: {
        type: 'spring',
        stiffness: 200,
        damping: 12,
        duration: 0.1,
      },
      scale: {
        type: 'spring',
        stiffness: 400,
        damping: 25,
      },
      opacity: {
        duration: 0.15,
      },
      when: 'afterChildren',
      staggerChildren: 0.03,
    },
  },
};

const formElementAnimation = {
  initial: { opacity: 0, y: 15, scale: 0.97 },
  animate: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      type: 'spring',
      stiffness: 400,
      damping: 25,
    },
  },
  exit: {
    opacity: 0,
    y: -8,
    scale: 0.97,
    transition: {
      duration: 0.1,
    },
  },
};

export const AuthSignup: React.FC = () => {
  const { signup } = useAuth();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [showEmailForm, setShowEmailForm] = useState(false);

  const form = useForm<SignupFormValues>({
    initialValues: {
      email: '',
      password: '',
    },
    validate: {
      email: (value) =>
        /^\S+@\S+$/.test(value) ? null : t('errors.invalidEmail'),
      password: (value) =>
        value.length >= 6 ? null : t('errors.minLength', { min: 6 }),
    },
  });

  const handleSubmit = async (values: SignupFormValues) => {
    try {
      await signup(values, i18n.languages[0].slice(0, 2));

      await Tracking.trackSignup({
        email: values.email,
        method: 'email',
      });

      showNotification({
        title: t('signup.emailNotificationTitle'),
        message: t('signup.emailNotificationMessage'),
        autoClose: false,
      });

      void navigate(AppRouteURL.home, { replace: true });
    } catch (error) {
      const apiError = error as ApiError;
      if (
        apiError.response?.status === 409 ||
        apiError.message?.includes('already exists')
      ) {
        showNotification({
          color: 'yellow',
          title: t('signup.userExistsTitle'),
          message: t('signup.userExistsMessage'),
          autoClose: 5000,
        });
        void navigate(AppRouteURL.login, { replace: true });
      } else {
        showNotification({
          color: 'red',
          title: t('signup.error'),
          message: t('signup.errorMessage'),
          autoClose: 5000,
        });
      }
      console.error('Signup error:', error);
    }
  };

  return (
    <Stack gap="md">
      <AnimatePresence mode="wait">
        {!showEmailForm ? (
          <LoginMethodsStack
            onEmailClick={() => {
              setShowEmailForm(true);
            }}
            mode="signup"
          />
        ) : (
          <motion.div
            variants={formAnimation}
            initial="initial"
            animate="animate"
            exit="exit"
            key="signup-form"
          >
            <MotionConfig reducedMotion="user">
              <form
                onSubmit={form.onSubmit(handleSubmit)}
                className={classes.form}
              >
                <Stack gap="md">
                  <motion.div variants={formElementAnimation}>
                    <AnimatedInputWrapper>
                      <TextInput
                        required
                        size="md"
                        label={t('signup.emailLabel')}
                        placeholder={t('signup.emailPlaceholder')}
                        {...form.getInputProps('email')}
                        leftSection={<IconMail size="1rem" />}
                        autoComplete="username"
                        classNames={{
                          input: classes.formInput,
                          label: classes.formLabel,
                        }}
                      />
                    </AnimatedInputWrapper>
                  </motion.div>

                  <motion.div variants={formElementAnimation}>
                    <AnimatedInputWrapper>
                      <PasswordInput
                        required
                        size="md"
                        label={t('signup.passwordLabel')}
                        placeholder={t('signup.passwordPlaceholder')}
                        {...form.getInputProps('password')}
                        leftSection={<IconLock size="1rem" />}
                        autoComplete="new-password"
                        classNames={{
                          input: classes.formInput,
                          label: classes.formLabel,
                        }}
                      />
                    </AnimatedInputWrapper>
                  </motion.div>

                  <motion.div variants={formElementAnimation}>
                    <Text
                      size="xs"
                      c="dimmed"
                      ta="center"
                      className={classes.termsText}
                    >
                      <Trans i18nKey="signup.agreement">
                        By signing up, you agree to our{' '}
                        <Anchor component={Link} to={AppRouteURL.terms}>
                          Terms of Service
                        </Anchor>{' '}
                        and{' '}
                        <Anchor component={Link} to={AppRouteURL.privacyPolicy}>
                          Privacy Policy
                        </Anchor>
                        .
                      </Trans>
                    </Text>
                  </motion.div>

                  <motion.div variants={formElementAnimation}>
                    <Stack gap="xs">
                      <Button
                        type="submit"
                        fullWidth
                        color="indigo"
                        size="lg"
                        className={classes.submitButton}
                      >
                        {t('signup.submitButton')}
                      </Button>
                      <Button
                        variant="subtle"
                        color="gray"
                        size="md"
                        onClick={() => {
                          setShowEmailForm(false);
                        }}
                      >
                        {t('labels.back')}
                      </Button>
                    </Stack>
                  </motion.div>
                </Stack>
              </form>
            </MotionConfig>
          </motion.div>
        )}
      </AnimatePresence>
    </Stack>
  );
};
