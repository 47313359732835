import { motion } from 'framer-motion';
import React, { useMemo } from 'react';

interface BackgroundIllustrationProps {
  activeTab: 'login' | 'signup';
}

export const BackgroundIllustration: React.FC<BackgroundIllustrationProps> = ({
  activeTab,
}) => {
  // Generate dots in a more distributed way
  const dots = useMemo(() => {
    const dotsArray = [];
    for (let i = 0; i < 30; i++) {
      // Create 3 rings of dots
      const ring = Math.floor(i / 10);
      const angleStep = (i % 10) * ((2 * Math.PI) / 10);
      const radius = (ring + 1) * 25; // 25%, 50%, 75% from center

      dotsArray.push({
        id: i,
        x: 50 + Math.cos(angleStep) * radius,
        y: 50 + Math.sin(angleStep) * radius,
        size: 2 + Math.random() * 2,
        delay: i * 0.1,
        duration: 3 + Math.random() * 2,
      });
    }
    return dotsArray;
  }, []);

  const primaryColor = activeTab === 'login' ? '#339AF0' : '#22B8CF';
  const secondaryColor = activeTab === 'login' ? '#228BE6' : '#15AABF';

  return (
    <motion.div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflow: 'hidden',
        zIndex: 0,
        pointerEvents: 'none',
      }}
    >
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid slice"
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          height: '100%',
        }}
      >
        {/* Background gradient */}
        <motion.rect
          x="0"
          y="0"
          width="100"
          height="100"
          fill="url(#gradient)"
          animate={{
            opacity: [0.6, 0.8, 0.6],
          }}
          transition={{
            duration: 8,
            repeat: Infinity,
            repeatType: 'reverse',
            ease: 'easeInOut',
          }}
        />

        {/* Large animated circles */}
        <motion.circle
          cx="25"
          cy="30"
          r="20"
          fill="url(#circle1)"
          animate={{
            scale: [1, 1.1, 1],
            opacity: [0.4, 0.5, 0.4],
          }}
          transition={{
            duration: 8,
            repeat: Infinity,
            repeatType: 'reverse',
            ease: 'easeInOut',
          }}
          style={{ transformOrigin: '25% 30%' }}
        />

        <motion.circle
          cx="80"
          cy="20"
          r="25"
          fill="url(#circle2)"
          animate={{
            scale: [1.1, 1, 1.1],
            opacity: [0.35, 0.45, 0.35],
          }}
          transition={{
            duration: 10,
            repeat: Infinity,
            repeatType: 'reverse',
            ease: 'easeInOut',
            delay: 1,
          }}
          style={{ transformOrigin: '80% 20%' }}
        />

        {/* Animated paths */}
        <motion.path
          d="M-10 60 Q 40 40, 80 60 T 170 60"
          stroke="url(#line1)"
          strokeWidth="0.3"
          fill="none"
          animate={{
            pathLength: [0, 1],
            opacity: [0.3, 0.5, 0.3],
          }}
          transition={{
            duration: 6,
            repeat: Infinity,
            repeatType: 'loop',
            ease: 'easeInOut',
          }}
        />

        <motion.path
          d="M-20 80 Q 30 60, 80 80 T 180 80"
          stroke="url(#line2)"
          strokeWidth="0.2"
          fill="none"
          animate={{
            pathLength: [0, 1],
            opacity: [0.25, 0.4, 0.25],
          }}
          transition={{
            duration: 8,
            repeat: Infinity,
            repeatType: 'loop',
            ease: 'easeInOut',
            delay: 2,
          }}
        />

        {/* Floating dots */}
        {dots.map(({ id, x, y, size, delay, duration }) => (
          <motion.circle
            key={id}
            cx={x}
            cy={y}
            r={size * 0.1} // Scale down the size to match viewBox
            fill={primaryColor}
            initial={{ opacity: 0.3 }}
            animate={{
              opacity: [0.4, 0.7, 0.4],
              y: [y, y - 1, y], // Subtle movement
            }}
            transition={{
              duration,
              repeat: Infinity,
              delay,
              ease: 'easeInOut',
            }}
          />
        ))}

        {/* Gradients definitions */}
        <defs>
          <linearGradient
            id="gradient"
            x1="0"
            y1="0"
            x2="100"
            y2="100"
            gradientUnits="userSpaceOnUse"
          >
            <motion.stop
              offset="0%"
              animate={{
                stopColor: activeTab === 'login' ? '#E7F5FF' : '#E3FAFC',
              }}
              transition={{ duration: 0.5 }}
            />
            <motion.stop
              offset="100%"
              animate={{
                stopColor: activeTab === 'login' ? '#D0EBFF' : '#C5F6FA',
              }}
              transition={{ duration: 0.5 }}
            />
          </linearGradient>

          <radialGradient
            id="circle1"
            cx="50%"
            cy="50%"
            r="50%"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0%" stopColor={primaryColor} stopOpacity="0.3" />
            <stop offset="100%" stopColor={primaryColor} stopOpacity="0" />
          </radialGradient>

          <radialGradient
            id="circle2"
            cx="50%"
            cy="50%"
            r="50%"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0%" stopColor={secondaryColor} stopOpacity="0.25" />
            <stop offset="100%" stopColor={secondaryColor} stopOpacity="0" />
          </radialGradient>

          <linearGradient
            id="line1"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="0%"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0%" stopColor={primaryColor} stopOpacity="0.4" />
            <stop offset="100%" stopColor={primaryColor} stopOpacity="0" />
          </linearGradient>

          <linearGradient
            id="line2"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="0%"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0%" stopColor={secondaryColor} stopOpacity="0.3" />
            <stop offset="100%" stopColor={secondaryColor} stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </motion.div>
  );
};
