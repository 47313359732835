import {
  Button,
  Card,
  Group,
  Stack,
  Text,
  Textarea,
  TextInput,
  Title,
  useMantineTheme,
  Transition,
  rem,
} from '@mantine/core';
import {
  IconArrowRight,
  IconLink,
  IconRobot,
  IconNotes,
  IconBrandChrome,
  IconClipboardText,
  IconX,
} from '@tabler/icons-react';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { JobAdType, MAX_JOB_AD_LENGTH } from '../../../models/Resume';
import { useCreateResumeMutation } from '../../../queries/ResumeQueries';
import { useWizard } from '../context/WizardContext';

export const JobInputStep: React.FC = () => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const {
    state: { jobInput },
    actions: { setJobInput, scanWebsite },
  } = useWizard();
  const createResumeMutation = useCreateResumeMutation();
  const [, setSearchParams] = useSearchParams();

  // Initialize selectedType based on current jobInput type
  const [selectedType, setSelectedType] = useState<JobAdType | null>(() =>
    jobInput.type === JobAdType.Text || jobInput.type === JobAdType.URL
      ? jobInput.type
      : null,
  );
  const [isScanning, setIsScanning] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);

  // Update selectedType when jobInput type changes
  useEffect(() => {
    if (jobInput.type === JobAdType.Text || jobInput.type === JobAdType.URL) {
      setSelectedType(jobInput.type);
    }
  }, [jobInput.type]);

  const handleScan = async () => {
    setIsScanning(true);
    try {
      await scanWebsite();
    } finally {
      setIsScanning(false);
    }
  };

  const handleTextContinue = async () => {
    if (!isTextValid) return;

    try {
      // Create resume immediately when continuing with text
      const resume = await createResumeMutation.mutateAsync({
        jobAdType: JobAdType.Text,
        ad: jobInput.content,
      });

      // Navigate to preferences with the new resume
      setSearchParams({ step: 'preferences', resumeId: resume.id });
    } catch (error) {
      // Handle error (you might want to show a notification here)
      console.error('Failed to create resume:', error);
    }
  };

  const handleTypeSelect = (type: JobAdType) => {
    setSelectedType(type);
    setIsTransitioning(true);
    setTimeout(() => {
      setJobInput(type, '');
      setIsTransitioning(false);
    }, 400);
  };

  const handleBack = () => {
    setIsTransitioning(true);
    setTimeout(() => {
      setSelectedType(null);
      setJobInput(null, '');
      setTimeout(() => {
        setIsTransitioning(false);
      }, 100);
    }, 300);
  };

  const isTextValid =
    jobInput.type === JobAdType.Text &&
    jobInput.content.trim().length >= 100 &&
    jobInput.content.length <= MAX_JOB_AD_LENGTH;

  return (
    <Stack align="center" w="100%">
      {/* Title section - completely separate */}
      <Stack gap="xs" align="center" maw={800} mb="xl">
        <Title order={2} ta="center" style={{ color: theme.colors.blue[7] }}>
          {t('create.wizard.jobInput.title')}
        </Title>
        <Text size="lg" c="dimmed" ta="center">
          {t('create.wizard.jobInput.description')}
        </Text>
      </Stack>

      {/* Content container with transitions */}
      <Stack
        gap="xl"
        align="center"
        style={{ position: 'relative', minHeight: rem(400) }}
        w="100%"
      >
        {/* Selection Cards */}
        <Transition
          mounted={!selectedType || isTransitioning}
          transition="fade"
          duration={400}
        >
          {(styles) => (
            <Group
              gap="xl"
              align="stretch"
              wrap="wrap"
              justify="center"
              style={styles}
            >
              <Transition
                mounted={!selectedType}
                transition={{
                  in: { opacity: 1, transform: 'translateY(0)' },
                  out: { opacity: 0, transform: 'translateY(20px)' },
                  common: { transformOrigin: 'center top' },
                  transitionProperty: 'transform, opacity',
                }}
                duration={400}
              >
                {(styles) => (
                  <Card
                    shadow="sm"
                    padding="xl"
                    radius="md"
                    withBorder
                    style={{
                      width: rem(340),
                      cursor: 'pointer',
                      transition: 'all 400ms cubic-bezier(0.4, 0, 0.2, 1)',
                      ...styles,
                      transform:
                        isTransitioning && selectedType === JobAdType.URL
                          ? 'scale(1.05) translateY(-10px)'
                          : styles.transform,
                      opacity:
                        isTransitioning && selectedType === JobAdType.URL
                          ? 0.9
                          : styles.opacity,
                      '&:hover': {
                        transform: 'translateY(-5px)',
                        boxShadow: theme.shadows.md,
                      },
                    }}
                    onClick={() => {
                      handleTypeSelect(JobAdType.URL);
                    }}
                  >
                    <Stack gap="md" align="center">
                      <IconBrandChrome
                        size={48}
                        style={{ color: theme.colors.blue[6] }}
                      />
                      <Title order={3} ta="center">
                        {t('create.wizard.jobInput.urlOption')}
                      </Title>
                      <Text size="sm" c="dimmed" ta="center">
                        {t('create.wizard.jobInput.urlDescription')}
                      </Text>
                      <Button
                        variant="light"
                        color="blue"
                        size="lg"
                        fullWidth
                        leftSection={<IconLink size={20} />}
                      >
                        {t('create.wizard.jobInput.urlButton')}
                      </Button>
                    </Stack>
                  </Card>
                )}
              </Transition>

              <Transition
                mounted={!selectedType}
                transition={{
                  in: { opacity: 1, transform: 'translateY(0)' },
                  out: { opacity: 0, transform: 'translateY(20px)' },
                  common: { transformOrigin: 'center top' },
                  transitionProperty: 'transform, opacity',
                }}
                duration={400}
              >
                {(styles) => (
                  <Card
                    shadow="sm"
                    padding="xl"
                    radius="md"
                    withBorder
                    style={{
                      width: rem(340),
                      cursor: 'pointer',
                      transition: 'all 400ms cubic-bezier(0.4, 0, 0.2, 1)',
                      ...styles,
                      transform:
                        isTransitioning && selectedType === JobAdType.Text
                          ? 'scale(1.05) translateY(-10px)'
                          : styles.transform,
                      opacity:
                        isTransitioning && selectedType === JobAdType.Text
                          ? 0.9
                          : styles.opacity,
                      '&:hover': {
                        transform: 'translateY(-5px)',
                        boxShadow: theme.shadows.md,
                      },
                    }}
                    onClick={() => {
                      handleTypeSelect(JobAdType.Text);
                    }}
                  >
                    <Stack gap="md" align="center">
                      <IconClipboardText
                        size={48}
                        style={{ color: theme.colors.violet[6] }}
                      />
                      <Title order={3} ta="center">
                        {t('create.wizard.jobInput.textOption')}
                      </Title>
                      <Text size="sm" c="dimmed" ta="center">
                        {t('create.wizard.jobInput.textDescription')}
                      </Text>
                      <Button
                        variant="light"
                        color="violet"
                        size="lg"
                        fullWidth
                        leftSection={<IconNotes size={20} />}
                      >
                        {t('create.wizard.jobInput.textButton')}
                      </Button>
                    </Stack>
                  </Card>
                )}
              </Transition>
            </Group>
          )}
        </Transition>

        {/* Input Fields */}
        <Transition
          mounted={!!selectedType && !isTransitioning}
          transition={{
            in: { opacity: 1, transform: 'scaleY(1)' },
            out: { opacity: 0, transform: 'scaleY(0.95)' },
            common: { transformOrigin: 'top' },
            transitionProperty: 'transform, opacity',
          }}
          duration={300}
          timingFunction="cubic-bezier(0.4, 0, 0.2, 1)"
        >
          {(styles) => (
            <Card
              shadow="sm"
              padding="xl"
              radius="md"
              withBorder
              style={{
                width: '100%',
                maxWidth: 600,
                ...styles,
                position: 'absolute',
                left: '50%',
                top: 0,
                transform: `translateX(-50%) ${styles.transform || ''}`.trim(),
              }}
            >
              <Stack gap="md">
                <Group justify="space-between" align="center">
                  <Group>
                    {selectedType === JobAdType.URL ? (
                      <IconBrandChrome
                        size={24}
                        style={{ color: theme.colors.blue[6] }}
                      />
                    ) : (
                      <IconClipboardText
                        size={24}
                        style={{ color: theme.colors.violet[6] }}
                      />
                    )}
                    <Text size="lg" fw={500}>
                      {t(
                        selectedType === JobAdType.URL
                          ? 'create.wizard.jobInput.urlOption'
                          : 'create.wizard.jobInput.textOption',
                      )}
                    </Text>
                  </Group>
                  <Button
                    variant="subtle"
                    color="gray"
                    size="sm"
                    onClick={handleBack}
                    leftSection={<IconX size={16} />}
                  >
                    {t('labels.back')}
                  </Button>
                </Group>

                {selectedType === JobAdType.URL ? (
                  <>
                    <TextInput
                      size="lg"
                      placeholder={t('create.wizard.jobInput.urlPlaceholder')}
                      value={jobInput.content}
                      onChange={(e) => {
                        setJobInput(JobAdType.URL, e.target.value);
                      }}
                      leftSection={<IconLink size={20} />}
                      style={{
                        input: {
                          backgroundColor: theme.colors.gray[0],
                          '&:focus': {
                            backgroundColor: theme.white,
                          },
                        },
                      }}
                    />
                    <Button
                      size="lg"
                      leftSection={<IconRobot size={20} />}
                      onClick={handleScan}
                      loading={isScanning}
                      disabled={!jobInput.content}
                      variant="gradient"
                      gradient={{
                        from: theme.colors.blue[5],
                        to: theme.colors.violet[5],
                      }}
                    >
                      {isScanning
                        ? t('create.wizard.jobInput.scanning')
                        : t('create.wizard.jobInput.scan')}
                    </Button>
                  </>
                ) : (
                  <>
                    <Textarea
                      size="lg"
                      placeholder={t('create.wizard.jobInput.textPlaceholder')}
                      value={jobInput.content}
                      onChange={(e) => {
                        setJobInput(JobAdType.Text, e.target.value);
                      }}
                      minRows={8}
                      maxRows={12}
                      autosize
                      maxLength={MAX_JOB_AD_LENGTH}
                      leftSection={<IconNotes size={20} />}
                      style={{
                        textarea: {
                          backgroundColor: theme.colors.gray[0],
                          '&:focus': {
                            backgroundColor: theme.white,
                          },
                        },
                      }}
                    />
                    <Button
                      size="lg"
                      rightSection={<IconArrowRight size={20} />}
                      onClick={handleTextContinue}
                      disabled={!isTextValid}
                      variant="gradient"
                      gradient={{
                        from: theme.colors.blue[5],
                        to: theme.colors.violet[5],
                      }}
                    >
                      {t('create.wizard.jobInput.continue')}
                    </Button>
                  </>
                )}
              </Stack>
            </Card>
          )}
        </Transition>
      </Stack>
    </Stack>
  );
};
