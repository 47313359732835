import { Button, Stack } from '@mantine/core';
import { IconMail } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { SignInWithAppleButton } from '../SignInWithAppleButton/SignInWithAppleButton';
import { SignInWithGoogleButton } from '../SignInWithGoogleButton/SignInWithGoogleButton';
import { SignInWithLinkedInButton } from '../SignInWithLinkedInButton/SignInWithLinkedInButton';
import { LINKEDIN_ENABLED } from '../../Config';
import classes from './LoginMethodsStack.module.css';

interface LoginMethodsStackProps {
  onEmailClick: () => void;
  mode: 'signin' | 'signup';
}

const containerAnimation = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
      when: 'beforeChildren',
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.2,
      when: 'afterChildren',
      staggerChildren: 0.05,
      staggerDirection: -1,
    },
  },
};

const buttonAnimation = {
  initial: { opacity: 0, y: 20 },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      stiffness: 400,
      damping: 25,
    },
  },
  exit: {
    opacity: 0,
    y: -10,
    transition: {
      duration: 0.2,
      ease: 'easeInOut',
    },
  },
};

export const LoginMethodsStack: React.FC<LoginMethodsStackProps> = ({
  onEmailClick,
  mode,
}) => {
  const { t } = useTranslation();

  return (
    <motion.div
      variants={containerAnimation}
      initial="initial"
      animate="animate"
      exit="exit"
      style={{ width: '100%', maxWidth: '375px', margin: '0 auto' }}
    >
      <Stack gap="md" align="stretch">
        <motion.div variants={buttonAnimation}>
          <Button
            h={48}
            fullWidth
            variant="gradient"
            gradient={{ from: 'indigo.4', to: 'indigo.7', deg: 90 }}
            size="lg"
            leftSection={
              <IconMail
                size={32}
                style={{
                  background: 'rgba(255, 255, 255, 0.2)',
                  padding: 4,
                  borderRadius: '50%',
                }}
              />
            }
            onClick={onEmailClick}
            classNames={{ root: classes.emailButton }}
            styles={{
              inner: {
                fontWeight: 600,
                fontSize: 16,
              },
            }}
          >
            {t(
              mode === 'signin'
                ? 'auth.signInWithEmail'
                : 'auth.signUpWithEmail',
            )}
          </Button>
        </motion.div>
        <motion.div variants={buttonAnimation}>
          <SignInWithGoogleButton
            text={mode === 'signin' ? 'signin_with' : 'signup_with'}
          />
        </motion.div>
        <motion.div variants={buttonAnimation}>
          <SignInWithAppleButton
            text={mode === 'signin' ? 'sign-in' : 'sign-up'}
          />
        </motion.div>
        {LINKEDIN_ENABLED && (
          <motion.div variants={buttonAnimation}>
            <SignInWithLinkedInButton
              text={
                mode === 'signin' ? 'signInWithLinkedIn' : 'signUpWithLinkedIn'
              }
            />
          </motion.div>
        )}
      </Stack>
    </motion.div>
  );
};
