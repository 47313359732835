import {
  Button,
  Card,
  Group,
  Image,
  Stack,
  Text,
  Title,
  useMantineTheme,
  Box,
  Container,
  Paper,
  rgba,
} from '@mantine/core';
import { IconArrowLeft, IconFileText } from '@tabler/icons-react';
import { motion } from 'framer-motion';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import robotImage from '../../../../components/GenerateResumeForm/robot_clipboard.webp';

interface ErrorPreviewProps {
  resumeId?: string;
}

export const ErrorPreview: React.FC<ErrorPreviewProps> = ({ resumeId }) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const [, setSearchParams] = useSearchParams();

  return (
    <Container size="md" py="xl">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.4, ease: [0.4, 0, 0.2, 1] }}
      >
        <Card
          withBorder
          padding={0}
          radius="lg"
          style={{
            backgroundColor: theme.white,
            border: `1px solid ${theme.colors.gray[2]}`,
            overflow: 'hidden',
          }}
        >
          {/* Hero section with illustration */}
          <Box
            style={{
              background: `linear-gradient(180deg, ${theme.colors.blue[0]} 0%, ${theme.colors.blue[1]} 100%)`,
              padding: '48px 0',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            {/* Decorative elements */}
            <Box
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: `radial-gradient(circle at 30% 20%, ${theme.colors.blue[1]} 0%, transparent 60%),
                           radial-gradient(circle at 70% 60%, ${theme.colors.blue[2]} 0%, transparent 60%)`,
                opacity: 0.6,
              }}
            />

            {/* Centered illustration */}
            <Box
              style={{
                width: '100%',
                maxWidth: 400,
                margin: '0 auto',
                position: 'relative',
                aspectRatio: '4/3',
              }}
            >
              <Image
                src={robotImage}
                alt="Friendly robot assistant with clipboard"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                }}
              />
            </Box>
          </Box>

          {/* Content section */}
          <Stack gap="xl" p="xl">
            <Stack gap="md" align="center">
              <Title
                order={2}
                ta="center"
                style={{ color: theme.colors.blue[9] }}
              >
                {t('create.wizard.preview.errorTitle')}
              </Title>

              <Text size="lg" ta="center" c="dimmed" maw={480}>
                {t('create.wizard.preview.errorDescription')}
              </Text>
            </Stack>

            {/* Solution box */}
            <Paper
              p="lg"
              radius="md"
              style={{
                backgroundColor: rgba(theme.colors.blue[0], 0.5),
                border: `1px solid ${theme.colors.blue[2]}`,
              }}
            >
              <Group gap="lg" align="flex-start">
                <Box
                  style={{
                    backgroundColor: theme.white,
                    padding: 8,
                    borderRadius: theme.radius.md,
                    boxShadow: theme.shadows.sm,
                  }}
                >
                  <IconFileText
                    size={24}
                    style={{ color: theme.colors.blue[6] }}
                  />
                </Box>
                <Stack gap={4} style={{ flex: 1 }}>
                  <Text fw={600} size="lg">
                    {t('create.wizard.preview.errorSolution')}
                  </Text>
                  <Text c="dimmed" size="sm">
                    {t('create.wizard.preview.errorSolutionHint')}
                  </Text>
                </Stack>
              </Group>
            </Paper>

            <Text size="sm" c="dimmed" ta="center">
              {t('create.wizard.preview.errorHint')}
            </Text>

            <Button
              size="lg"
              variant="gradient"
              gradient={{
                from: theme.colors.blue[5],
                to: theme.colors.violet[5],
                deg: 90,
              }}
              leftSection={<IconArrowLeft size={20} />}
              onClick={() => {
                const params: Record<string, string> = { step: 'input' };
                if (resumeId) {
                  params.resumeId = resumeId;
                }
                setSearchParams(params);
              }}
              fullWidth
            >
              {t('create.wizard.preview.tryAgain')}
            </Button>
          </Stack>
        </Card>
      </motion.div>
    </Container>
  );
};
