import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { AppRouteURL } from '../AppRouteURL';
import { ListResumesPage } from '../pages/ListResumesPage/ListResumesPage';
import { NewApplicationWizard } from '../components/NewApplicationWizard/NewApplicationWizard';

const ViewResumePage = lazy(
  () =>
    import(
      /* webpackChunkName: "view-resume" */ '../pages/ViewResumePage/ViewResumePage'
    ),
);

export const getResumeRoutes = () => (
  <Route path={AppRouteURL.resumes.index}>
    <Route path="list" element={<ListResumesPage />} />

    <Route path="create" element={<NewApplicationWizard />} />

    <Route
      path={`view/:resumeId?/:tab?/:interviewId?/:action?`}
      element={<ViewResumePage />}
    />
  </Route>
);
