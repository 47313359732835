import {
  Button,
  Card,
  Group,
  Radio,
  Select,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { IconArrowLeft, IconArrowRight } from '@tabler/icons-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import {
  ResumeLanguage,
  ResumeLanguageCode,
  ResumeStyles,
  JobAdType,
} from '../../../models/Resume';
import { useWizard } from '../context/WizardContext';

const STYLE_DESCRIPTIONS: Record<(typeof ResumeStyles)[number], string> = {
  professional: 'create.wizard.preferences.styleDescriptions.professional',
  formal: 'create.wizard.preferences.styleDescriptions.formal',
  casual: 'create.wizard.preferences.styleDescriptions.casual',
  playful: 'create.wizard.preferences.styleDescriptions.playful',
};

export const PreferencesStep: React.FC = () => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const {
    state: { preferences, jobInput },
    actions: { setPreferences, goToStep },
  } = useWizard();

  const languageOptions = Object.entries(ResumeLanguage).map(
    ([code, label]) => ({
      value: code,
      label,
    }),
  );

  const handleNext = () => {
    goToStep('instructions');
  };

  const canContinue = preferences.language && preferences.style;

  return (
    <motion.div
      initial={{ opacity: 0, x: 50 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.4, ease: [0.4, 0, 0.2, 1] }}
    >
      <Stack gap="xl" align="center" py="xl">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1, duration: 0.4 }}
        >
          <Stack gap="xs" align="center" maw={600}>
            <Title
              order={2}
              ta="center"
              style={{ color: theme.colors.blue[7] }}
            >
              {t('create.wizard.preferences.title')}
            </Title>
            <Text size="lg" c="dimmed" ta="center">
              {t('create.wizard.preferences.description')}
            </Text>
          </Stack>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: 0.2, duration: 0.4 }}
          style={{ width: '100%', maxWidth: 600 }}
        >
          <Card withBorder p="xl" radius="md">
            <Stack gap="xl">
              <Stack gap="xs">
                <Title order={3} size="h4">
                  {t('create.wizard.preferences.languageTitle')}
                </Title>
                <Text c="dimmed">
                  {t('create.wizard.preferences.languageDescription')}
                </Text>
                <Select
                  data={languageOptions}
                  value={preferences.language}
                  onChange={(value) => {
                    setPreferences({
                      ...preferences,
                      language: value as ResumeLanguageCode,
                    });
                  }}
                  placeholder={t(
                    'create.wizard.preferences.languagePlaceholder',
                  )}
                />
              </Stack>

              <Stack gap="xs">
                <Title order={3} size="h4">
                  {t('create.wizard.preferences.styleTitle')}
                </Title>
                <Text c="dimmed">
                  {t('create.wizard.preferences.styleDescription')}
                </Text>
                <Radio.Group
                  value={preferences.style}
                  onChange={(value) => {
                    setPreferences({
                      ...preferences,
                      style: value as (typeof ResumeStyles)[number],
                    });
                  }}
                >
                  <Stack gap="xs">
                    {ResumeStyles.map((style) => (
                      <motion.div
                        key={style}
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                        transition={{
                          type: 'spring',
                          stiffness: 400,
                          damping: 25,
                        }}
                      >
                        <Card
                          withBorder
                          padding="md"
                          radius="md"
                          style={{
                            borderColor:
                              preferences.style === style
                                ? theme.colors.blue[5]
                                : undefined,
                            backgroundColor:
                              preferences.style === style
                                ? theme.colors.blue[0]
                                : undefined,
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setPreferences({
                              ...preferences,
                              style,
                            });
                          }}
                        >
                          <Group align="flex-start" wrap="nowrap">
                            <Radio
                              value={style}
                              label={null}
                              styles={{
                                inner: {
                                  alignSelf: 'flex-start',
                                  marginTop: 4,
                                },
                              }}
                            />
                            <Stack gap={4}>
                              <Text fw={500}>
                                {t(`create.wizard.preferences.styles.${style}`)}
                              </Text>
                              <Text size="sm" c="dimmed">
                                {t(STYLE_DESCRIPTIONS[style])}
                              </Text>
                            </Stack>
                          </Group>
                        </Card>
                      </motion.div>
                    ))}
                  </Stack>
                </Radio.Group>
              </Stack>
            </Stack>
          </Card>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3, duration: 0.4 }}
        >
          <Group gap="xl">
            <Button
              size="lg"
              variant="light"
              leftSection={<IconArrowLeft size={20} />}
              onClick={() => {
                goToStep(
                  jobInput.type === JobAdType.Text ? 'input' : 'preview',
                );
              }}
            >
              {t('labels.back')}
            </Button>
            <Button
              size="lg"
              rightSection={<IconArrowRight size={20} />}
              onClick={handleNext}
              disabled={!canContinue}
              variant="gradient"
              gradient={{
                from: theme.colors.blue[5],
                to: theme.colors.violet[5],
              }}
            >
              {t('labels.next')}
            </Button>
          </Group>
        </motion.div>
      </Stack>
    </motion.div>
  );
};
