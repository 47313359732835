import {
  Anchor,
  AppShell,
  Box,
  Center,
  Divider,
  NavLink,
  NavLinkProps,
  ScrollArea,
  Text,
  ThemeIcon,
} from '@mantine/core';
import {
  IconBookmarks,
  IconFile,
  IconFileTypePdf,
  IconFlare,
  IconInfoCircle,
  IconListDetails,
  IconMail,
  IconMessageChatbot,
  IconPencil,
  IconTrophy,
  IconUser,
  IconWand,
} from '@tabler/icons-react';
import { TFunction } from 'i18next';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  LinkProps,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { AppRouteURL } from '../../AppRouteURL';
import { IResume, splitTitleText } from '../../models/Resume';
import { ResumeStatusIcon } from '../../pages/ListResumesPage/ResumeStatusIcon';
import { ResumeTitleText } from '../../pages/ListResumesPage/ResumeTitleText';
import { useResumesQuery } from '../../queries/ResumeQueries';
import { CreateResumeButton } from '../CreateResumeButton/CreateResumeButton';

const MAX_RESUMES_IN_MENU = 5;

type MenuNavLinkProps = NavLinkProps &
  LinkProps & { setOpen: (open: boolean) => void };

const MenuNavLink: React.FC<MenuNavLinkProps> = ({ setOpen, ...props }) => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <NavLink
      component={Link}
      active={!props.disabled && location.pathname === props.to}
      onClick={() => {
        setOpen(false);
        if (props.to) {
          navigate(props.to);
        }
      }}
      aria-current={location.pathname === props.to ? 'page' : undefined}
      aria-expanded={props.opened}
      tabIndex={0}
      {...props}
    />
  );
};

type ResumeNavLinkProps = Omit<MenuNavLinkProps, 'to'> & {
  resume: IResume;
  to?: string;
};

const ResumeNavLink: React.FC<ResumeNavLinkProps> = ({ resume, ...props }) => {
  const location = useLocation();

  const { label, description } = splitTitleText(resume);

  return (
    <MenuNavLink
      {...props}
      active={
        !props.disabled &&
        location.pathname.startsWith(`${AppRouteURL.resumes.view}/${resume.id}`)
      }
      to={`${AppRouteURL.resumes.view}/${resume.id}`}
      leftSection={<ResumeStatusIcon status={resume.status} size={16} />}
      label={
        <Text fz="xs" truncate="end" maw="90%">
          {label || <ResumeTitleText resume={resume} />}
        </Text>
      }
      description={description}
    />
  );
};

const ApplicationMenu: React.FC<{
  setOpen: MenuNavLinkProps['setOpen'];
  resume: IResume;
  t: TFunction;
}> = ({ setOpen, resume, t }) => (
  <AppShell.Section>
    <Divider
      my="lg"
      label={
        <Text tt="uppercase" c="dimmed" fz="xs">
          {t('header.currentApplication')}
        </Text>
      }
    />
    <MenuNavLink
      leftSection={
        <ThemeIcon variant="default" c="dimmed" aria-hidden="true">
          <IconBookmarks />
        </ThemeIcon>
      }
      to="#current-application-nav-link"
      label={
        <Text fz="sm" truncate="end">
          {resume.title}
        </Text>
      }
      setOpen={setOpen}
      defaultOpened
    >
      <MenuNavLink
        leftSection={
          <ThemeIcon variant="default" c="dimmed" aria-hidden="true">
            <IconFile />
          </ThemeIcon>
        }
        to={`${AppRouteURL.resumes.view}/${resume.id}`}
        label={t('view.resumeTab')}
        setOpen={setOpen}
      />
      <MenuNavLink
        leftSection={
          <ThemeIcon variant="default" c="dimmed" aria-hidden="true">
            <IconMail />
          </ThemeIcon>
        }
        to={`${AppRouteURL.resumes.view}/${resume.id}/letter`}
        label={t('view.coverLetterTab')}
        setOpen={setOpen}
      />
      <MenuNavLink
        leftSection={
          <ThemeIcon variant="default" c="dimmed" aria-hidden="true">
            <IconInfoCircle />
          </ThemeIcon>
        }
        to={`${AppRouteURL.resumes.view}/${resume.id}/info`}
        label={t('view.infoTab')}
        setOpen={setOpen}
      />
      <MenuNavLink
        leftSection={
          <ThemeIcon variant="default" c="dimmed" aria-hidden="true">
            <IconPencil />
          </ThemeIcon>
        }
        to={`${AppRouteURL.resumes.view}/${resume.id}/history`}
        label={t('view.historyTab')}
        setOpen={setOpen}
      />
      <MenuNavLink
        leftSection={
          <ThemeIcon variant="default" c="dimmed" aria-hidden="true">
            <IconFileTypePdf />
          </ThemeIcon>
        }
        to={`${AppRouteURL.resumes.view}/${resume.id}/pdf`}
        label={t('view.pdfTab')}
        setOpen={setOpen}
      />
      <MenuNavLink
        leftSection={
          <ThemeIcon variant="default" c="dimmed" aria-hidden="true">
            <IconMessageChatbot />
          </ThemeIcon>
        }
        to={`${AppRouteURL.resumes.view}/${resume.id}/interview`}
        label={t('view.interviewTab')}
        setOpen={setOpen}
      />
    </MenuNavLink>
  </AppShell.Section>
);

export const AppNavbar: React.FC<{ setOpen: (open: boolean) => void }> = ({
  setOpen,
}) => {
  const { t } = useTranslation();
  const { resumeId } = useParams();

  const { data: resumes } = useResumesQuery();

  const currentResume = useMemo(
    () => resumes?.find((r) => r.id === resumeId),
    [resumes, resumeId],
  );

  return (
    <AppShell.Navbar p="md">
      <ScrollArea
        scrollbars="y"
        styles={{
          viewport: { display: 'block', flexGrow: 1 },
          root: { display: 'block', flexGrow: 1 },
        }}
      >
        <Box
          style={{ display: 'block' }}
          maw={{ base: '100%', sm: 180, md: 280 }}
        >
          <AppShell.Section>
            <Center mb="xl">
              <CreateResumeButton
                onClick={() => {
                  setOpen(false);
                }}
              />
            </Center>
          </AppShell.Section>

          <AppShell.Section>
            <NavLink
              href="#profile-nav-section"
              label={t('header.profile')}
              leftSection={
                <ThemeIcon variant="default" c="dimmed" aria-hidden="true">
                  <IconUser />
                </ThemeIcon>
              }
              defaultOpened={!currentResume}
              aria-expanded={!currentResume}
              tabIndex={0}
            >
              <MenuNavLink
                leftSection={
                  <ThemeIcon variant="default" c="dimmed">
                    <IconUser />
                  </ThemeIcon>
                }
                to={AppRouteURL.profileTabBasic}
                label={
                  <Text size="sm" id="app-header-profile-link">
                    {t('profile.personal')}
                  </Text>
                }
                setOpen={setOpen}
              />
              <MenuNavLink
                leftSection={
                  <ThemeIcon variant="default" c="dimmed">
                    <IconFlare />
                  </ThemeIcon>
                }
                to={AppRouteURL.profileTabIntro}
                label={<Text size="sm">{t('profile.intro')}</Text>}
                setOpen={setOpen}
              />
              <MenuNavLink
                leftSection={
                  <ThemeIcon variant="default" c="dimmed">
                    <IconListDetails />
                  </ThemeIcon>
                }
                to={AppRouteURL.profileTabTimeline}
                label={<Text size="sm">{t('profile.timelineLabel')}</Text>}
                setOpen={setOpen}
              />
            </NavLink>
            <Divider
              my="lg"
              label={
                <Text tt="uppercase" c="dimmed" fz="xs">
                  {t('navbar.aiHeader')}
                </Text>
              }
            />
            <MenuNavLink
              leftSection={
                <ThemeIcon variant="default" c="dimmed">
                  <IconWand />
                </ThemeIcon>
              }
              to={AppRouteURL.analyze}
              label={
                <Text size="sm" id="app-header-profile-link">
                  {t('navbar.analyzer')}
                </Text>
              }
              setOpen={setOpen}
            />
            <MenuNavLink
              leftSection={
                <ThemeIcon variant="default" c="dimmed">
                  <IconTrophy />
                </ThemeIcon>
              }
              to={AppRouteURL.achievements}
              label={<Text size="sm">{t('navbar.achievements')}</Text>}
              setOpen={setOpen}
            />
          </AppShell.Section>

          {currentResume && (
            <ApplicationMenu setOpen={setOpen} resume={currentResume} t={t} />
          )}

          <AppShell.Section grow component={ScrollArea}>
            <Divider
              my="lg"
              label={
                <Text tt="uppercase" c="dimmed" fz="xs">
                  {t('header.applications')}
                </Text>
              }
            />
            <MenuNavLink
              leftSection={
                <ThemeIcon variant="default" c="dimmed">
                  <IconBookmarks />
                </ThemeIcon>
              }
              to={AppRouteURL.resumes.list}
              label={
                <Text size="sm" id="app-header-applications-link">
                  {t('applications.pageTitle')}
                </Text>
              }
              setOpen={setOpen}
              opened={!currentResume}
            >
              {resumes
                ?.slice(0, MAX_RESUMES_IN_MENU)
                .map((resume) => (
                  <ResumeNavLink
                    resume={resume}
                    setOpen={setOpen}
                    key={resume.id}
                  />
                ))}

              {!resumes ||
                (resumes.length === 0 && (
                  <Text fz="sm" ta="center" c="dimmed">
                    {t('applications.noApplications')}
                  </Text>
                ))}

              {resumes && resumes.length > MAX_RESUMES_IN_MENU && (
                <Anchor component={Link} to={AppRouteURL.resumes.list}>
                  <Text fz="sm" ta="right" c="dimmed">
                    +{resumes.length - MAX_RESUMES_IN_MENU}
                  </Text>
                </Anchor>
              )}
            </MenuNavLink>
          </AppShell.Section>
        </Box>
      </ScrollArea>
      <AppShell.Section>
        <Divider mb="sm" />
        <Text fz="xs" c="dimmed">
          {t('login.copyrightFooter')}
        </Text>
      </AppShell.Section>
    </AppShell.Navbar>
  );
};
