import React from 'react';
import { notifications } from '@mantine/notifications';
import { TFunction } from 'i18next';
import { IUserAchievementEntity } from '../../services/Achievements';
import { CelebrationEffect } from '../CelebrationEffect/CelebrationEffect';
import { createRoot } from 'react-dom/client';

// Define keyframe animation for the achievement popup effect
const keyframes = `
@keyframes achievement-popup {
  0% { 
    opacity: 0;
    transform: translate3d(0, 20px, 0) scale(0.9);
  }
  15% { 
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1.02);
  }
  25% { 
    transform: translate3d(0, 0, 0) scale(1);
  }
  80% { 
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  100% { 
    opacity: 0;
    transform: translate3d(0, -10px, 0);
    visibility: hidden;
  }
}

@keyframes achievement-glow {
  0% { box-shadow: 0 0 20px rgba(51, 154, 240, 0.2); }
  50% { box-shadow: 0 0 30px rgba(51, 154, 240, 0.4); }
  100% { box-shadow: 0 0 20px rgba(51, 154, 240, 0.2); }
}`;

// Add keyframes to document
if (typeof document !== 'undefined') {
  const style = document.createElement('style');
  style.innerHTML = keyframes;
  document.head.appendChild(style);
}

export const ACHIEVEMENT_NOTIFICATION_STYLES = {
  root: {
    backgroundColor: 'var(--mantine-color-dark-8)',
    border: '2px solid var(--mantine-color-blue-5)',
    borderRadius: '0.75rem',
    padding: '1rem',
    boxShadow: '0 0 20px rgba(51, 154, 240, 0.2)',
    animation:
      'achievement-glow 2s ease-in-out infinite, achievement-popup 8s cubic-bezier(0.16, 1, 0.3, 1) forwards',
    width: '400px',
    maxWidth: '90vw',
    willChange: 'transform, opacity',
    perspective: 1000,
    transformStyle: 'preserve-3d',
  },
  title: {
    color: 'var(--mantine-color-blue-4)',
    fontSize: '1.2rem',
    fontWeight: 700,
  },
  description: {
    color: 'var(--mantine-color-gray-2)',
  },
  closeButton: {
    color: 'var(--mantine-color-gray-5)',
    '&:hover': {
      backgroundColor: 'var(--mantine-color-dark-7)',
    },
  },
} as const;

export const showAchievementNotification = (
  achievement: IUserAchievementEntity,
  t: TFunction,
): void => {
  const message = (
    <React.Fragment>
      <div style={{ marginBottom: '0.5rem' }}>
        {t(`achievements.items.${achievement.achievement.key}.description`)}
      </div>
      <div
        style={{
          color: 'var(--mantine-color-blue-4)',
          fontWeight: 600,
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem',
        }}
      >
        <span>+{achievement.achievement.points}</span>
        <span style={{ fontSize: '0.9em' }}>
          {t('achievements.points', { points: '' })}
        </span>
      </div>
    </React.Fragment>
  );

  // Create a portal for fireworks
  const fireworksRoot = document.createElement('div');
  document.body.appendChild(fireworksRoot);

  // Render fireworks
  const root = createRoot(fireworksRoot);
  root.render(<CelebrationEffect />);

  // Cleanup after animation
  setTimeout(() => {
    root.unmount();
    fireworksRoot.remove();
  }, 3000);

  // Show notification as usual
  notifications.show({
    title: `🏆 ${t(`achievements.items.${achievement.achievement.key}.name`) as string}`,
    message,
    styles: ACHIEVEMENT_NOTIFICATION_STYLES,
    autoClose: 8000,
    withCloseButton: true,
    withBorder: false,
    icon: achievement.achievement.icon || '🏆',
    position: 'bottom-center',
  });
};
