import { Anchor, Button, Stack, TextInput, PasswordInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconLock, IconMail } from '@tabler/icons-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence, MotionConfig } from 'framer-motion';
import { AppRouteURL } from '../../AppRouteURL';
import { useAuth } from '../../Auth';
import { PRODUCT_NAME } from '../../Constants';
import { LoginMethodsStack } from './LoginMethodsStack';
import { AnimatedInputWrapper } from '../AnimatedInputWrapper/AnimatedInputWrapper';
import classes from './AuthForms.module.css';

interface LoginFormValues {
  email: string;
  password: string;
}

const formAnimation = {
  initial: { opacity: 0, height: 0, scale: 0.98 },
  animate: {
    opacity: 1,
    height: 'auto',
    scale: 1,
    transition: {
      height: {
        type: 'spring',
        stiffness: 200,
        damping: 10,
        duration: 0.3,
      },
      scale: {
        type: 'spring',
        stiffness: 400,
        damping: 25,
      },
      opacity: {
        duration: 0.15,
      },
      when: 'beforeChildren',
      staggerChildren: 0.05,
      delayChildren: 0.02,
    },
  },
  exit: {
    opacity: 0,
    height: 0,
    scale: 0.98,
    transition: {
      height: {
        type: 'spring',
        stiffness: 200,
        damping: 12,
        duration: 0.1,
      },
      scale: {
        type: 'spring',
        stiffness: 400,
        damping: 25,
      },
      opacity: {
        duration: 0.15,
      },
      when: 'afterChildren',
      staggerChildren: 0.03,
    },
  },
};

const formElementAnimation = {
  initial: { opacity: 0, y: 15, scale: 0.97 },
  animate: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      type: 'spring',
      stiffness: 400,
      damping: 25,
    },
  },
  exit: {
    opacity: 0,
    y: -8,
    scale: 0.97,
    transition: {
      duration: 0.1,
    },
  },
};

export const AuthLogin: React.FC = () => {
  const { signin } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showEmailForm, setShowEmailForm] = useState(false);

  const form = useForm<LoginFormValues>({
    initialValues: {
      email: '',
      password: '',
    },
    validate: {
      email: (value) =>
        /^\S+@\S+$/.test(value) ? null : t('errors.invalidEmail'),
      password: (value) =>
        value.length >= 6 ? null : t('errors.minLength', { min: 6 }),
    },
  });

  const handleSubmit = async (values: LoginFormValues) => {
    try {
      await signin(values);
      void navigate(AppRouteURL.home, { replace: true });
    } catch (error) {
      showNotification({
        color: 'red',
        title: t('login.error'),
        message: t('login.errorMessage'),
        autoClose: 5000,
      });
      console.error('Login error:', error);
    }
  };

  return (
    <Stack gap="md">
      <AnimatePresence mode="wait">
        {!showEmailForm ? (
          <LoginMethodsStack
            onEmailClick={() => {
              setShowEmailForm(true);
            }}
            mode="signin"
          />
        ) : (
          <motion.div
            variants={formAnimation}
            initial="initial"
            animate="animate"
            exit="exit"
            key="login-form"
          >
            <MotionConfig reducedMotion="user">
              <form
                onSubmit={form.onSubmit(handleSubmit)}
                className={classes.form}
              >
                <Stack gap="md">
                  <motion.div variants={formElementAnimation}>
                    <AnimatedInputWrapper>
                      <TextInput
                        required
                        size="md"
                        label={t('login.emailLabel')}
                        placeholder={t('login.emailPlaceholder')}
                        {...form.getInputProps('email')}
                        leftSection={<IconMail size="1rem" />}
                        autoComplete="username"
                        classNames={{
                          input: classes.formInput,
                          label: classes.formLabel,
                        }}
                      />
                    </AnimatedInputWrapper>
                  </motion.div>

                  <motion.div variants={formElementAnimation}>
                    <AnimatedInputWrapper>
                      <PasswordInput
                        required
                        size="md"
                        label={t('login.passwordLabel')}
                        placeholder={t('login.passwordPlaceholder')}
                        {...form.getInputProps('password')}
                        leftSection={<IconLock size="1rem" />}
                        autoComplete="current-password"
                        classNames={{
                          input: classes.formInput,
                          label: classes.formLabel,
                        }}
                      />
                    </AnimatedInputWrapper>
                  </motion.div>

                  <motion.div variants={formElementAnimation}>
                    <Anchor
                      component={Link}
                      to={AppRouteURL.forgotPassword}
                      size="sm"
                      className={classes.forgotPassword}
                      mt={-8}
                    >
                      {t('login.forgotPassword')}
                    </Anchor>
                  </motion.div>

                  <motion.div variants={formElementAnimation}>
                    <Stack gap="xs">
                      <Button
                        type="submit"
                        fullWidth
                        color="indigo"
                        size="lg"
                        className={classes.submitButton}
                      >
                        {t('login.signIn', { brand: PRODUCT_NAME })}
                      </Button>
                      <Button
                        variant="subtle"
                        color="gray"
                        size="md"
                        onClick={() => {
                          setShowEmailForm(false);
                        }}
                      >
                        {t('labels.back')}
                      </Button>
                    </Stack>
                  </motion.div>
                </Stack>
              </form>
            </MotionConfig>
          </motion.div>
        )}
      </AnimatePresence>
    </Stack>
  );
};
