import {
  Button,
  Card,
  Group,
  Stack,
  Text,
  Textarea,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { IconArrowLeft, IconRobot } from '@tabler/icons-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { useWizard } from '../context/WizardContext';

const EXAMPLE_INSTRUCTIONS = [
  'create.wizard.instructions.examples.leadership',
  'create.wizard.instructions.examples.technical',
  'create.wizard.instructions.examples.experience',
  'create.wizard.instructions.examples.skills',
];

export const InstructionsStep: React.FC = () => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const {
    state: { preferences },
    actions: { setPreferences, goToStep, startGeneration },
  } = useWizard();

  const handleStart = async () => {
    await startGeneration();
    goToStep('generation');
  };

  return (
    <motion.div
      initial={{ opacity: 0, x: 50 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.4, ease: [0.4, 0, 0.2, 1] }}
    >
      <Stack gap="xl" align="center" py="xl">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1, duration: 0.4 }}
        >
          <Stack gap="xs" align="center" maw={600}>
            <Title
              order={2}
              ta="center"
              style={{ color: theme.colors.blue[7] }}
            >
              {t('create.wizard.instructions.title')}
            </Title>
            <Text size="lg" c="dimmed" ta="center">
              {t('create.wizard.instructions.description')}
            </Text>
          </Stack>
        </motion.div>

        <Stack gap="xl" style={{ width: '100%', maxWidth: 600 }}>
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.2, duration: 0.4 }}
          >
            <Card withBorder p="xl" radius="md">
              <Stack gap="md">
                <Title order={3}>
                  {t('create.wizard.instructions.examplesTitle')}
                </Title>
                <Text c="dimmed">
                  {t('create.wizard.instructions.examplesDescription')}
                </Text>
                <Stack gap="xs">
                  {EXAMPLE_INSTRUCTIONS.map((example, index) => (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: 0.3 + index * 0.1, duration: 0.4 }}
                    >
                      <Group gap="xs">
                        <Text size="sm" c="dimmed">
                          •
                        </Text>
                        <Text size="sm" c="dimmed" style={{ flex: 1 }}>
                          {t(example)}
                        </Text>
                      </Group>
                    </motion.div>
                  ))}
                </Stack>
              </Stack>
            </Card>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.4, duration: 0.4 }}
          >
            <Card withBorder p="xl" radius="md">
              <Stack gap="md">
                <Title order={3}>
                  {t('create.wizard.instructions.inputTitle')}
                </Title>
                <Text c="dimmed">
                  {t('create.wizard.instructions.inputDescription')}
                </Text>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.5, duration: 0.4 }}
                >
                  <Textarea
                    value={preferences.instructions || ''}
                    onChange={(e) => {
                      setPreferences({ instructions: e.currentTarget.value });
                    }}
                    placeholder={t('create.wizard.instructions.placeholder')}
                    minRows={4}
                    maxRows={8}
                    autosize
                    size="lg"
                  />
                </motion.div>
              </Stack>
            </Card>
          </motion.div>
        </Stack>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6, duration: 0.4 }}
        >
          <Group gap="xl">
            <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
              <Button
                size="lg"
                variant="light"
                leftSection={<IconArrowLeft size={20} />}
                onClick={() => {
                  goToStep('preferences');
                }}
              >
                {t('create.wizard.instructions.back')}
              </Button>
            </motion.div>
            <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
              <Button
                size="lg"
                leftSection={<IconRobot size={20} />}
                onClick={handleStart}
                variant="gradient"
                gradient={{
                  from: theme.colors.blue[5],
                  to: theme.colors.violet[5],
                }}
              >
                {t('create.wizard.instructions.start')}
              </Button>
            </motion.div>
          </Group>
        </motion.div>
      </Stack>
    </motion.div>
  );
};
