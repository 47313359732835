import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { ResumeStatus } from '../../../models/Resume';
import { useResumeQuery } from '../../../queries/ResumeQueries';
import { useWizard } from '../context/WizardContext';
import { LoadingPreview } from './PreviewStep/LoadingPreview';
import { ErrorPreview } from './PreviewStep/ErrorPreview';
import { WebsitePreview } from './PreviewStep/WebsitePreview';

export const PreviewStep: React.FC = () => {
  const {
    state: { generation },
    actions: { confirmJobAd },
  } = useWizard();
  const [, setSearchParams] = useSearchParams();

  const { data: resume, isLoading } = useResumeQuery(generation.resumeId);

  if (isLoading || resume?.status === ResumeStatus.Preparing) {
    return <LoadingPreview status={resume?.status} />;
  }

  if (resume?.status === ResumeStatus.InvalidURL || !resume) {
    return <ErrorPreview resumeId={resume?.id} />;
  }

  if (resume.status === ResumeStatus.WebsiteParsed) {
    return (
      <WebsitePreview
        resumeId={resume.id}
        onConfirm={confirmJobAd}
        onReject={() => {
          const params: Record<string, string> = { step: 'input' };
          if (resume.id) {
            params.resumeId = resume.id;
          }
          setSearchParams(params);
        }}
      />
    );
  }

  return null;
};
