import {
  Paper,
  Progress,
  Stack,
  Text,
  Title,
  rem,
  Group,
  ThemeIcon,
} from '@mantine/core';
import { IconUserCircle, IconStarFilled } from '@tabler/icons-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRouteURL } from '../../AppRouteURL';
import { useProfileCompleteness } from '../../models/ProfileQueries';
import { motion } from 'framer-motion';

import classes from './HomeCard.module.css';

const ProfileStatusCard: React.FC = () => {
  const { t } = useTranslation();
  const profileCompleteness = useProfileCompleteness();
  const navigate = useNavigate();

  const getStatusColor = (value: number) => {
    if (value === 100) return 'teal';
    if (value >= 80) return 'blue';
    if (value >= 50) return 'yellow';
    return 'blue';
  };

  return (
    <Paper
      shadow="lg"
      radius="xl"
      p="xl"
      onClick={() => {
        navigate(AppRouteURL.profileTabBasic);
      }}
      className={classes.homeCard}
      component={motion.div}
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
    >
      <div className={classes.homeCardWrapper}>
        <Group justify="space-between" align="center">
          <Title order={5}>{t('header.profile')}</Title>
          <IconUserCircle
            style={{ width: rem(24), height: rem(24) }}
            color="var(--mantine-color-blue-filled)"
          />
        </Group>
        <div className={classes.homeCardContent}>
          <Stack gap="md" align="stretch" justify="center" h="100%" w="100%">
            <Stack gap={4} align="center">
              <Group gap={8} align="baseline">
                <Text size="4rem" ta="center" fw={700}>
                  {profileCompleteness}
                </Text>
                <Text size="xl" fw={500}>
                  %
                </Text>
              </Group>
              {profileCompleteness === 100 && (
                <ThemeIcon size="lg" radius="xl" color="teal" variant="light">
                  <IconStarFilled style={{ width: rem(18), height: rem(18) }} />
                </ThemeIcon>
              )}
            </Stack>
            <Stack gap="xs">
              <Progress
                value={profileCompleteness}
                size="xl"
                radius="xl"
                color={getStatusColor(profileCompleteness)}
                striped={profileCompleteness < 100}
                animated={profileCompleteness < 100}
              />
              <Group gap={4} justify="space-between">
                <Text size="xs" c="dimmed">
                  0%
                </Text>
                <Text size="xs" c="dimmed">
                  100%
                </Text>
              </Group>
            </Stack>
            <Text size="sm" c="dimmed" ta="center">
              {profileCompleteness === 100
                ? t('home.profile.complete')
                : t('home.profile.clickToComplete')}
            </Text>
          </Stack>
        </div>
      </div>
    </Paper>
  );
};

export default ProfileStatusCard;
