export interface IResumeJSONExperience {
  start: string;
  end?: string;
  title: string;
  summary: string[];
  company: {
    name: string;
    location?: string;
  };
}

export interface IResumeJSONEducation {
  start: string;
  end?: string;
  degree: string;
  fieldOfStudy: string;
  schoolName: string;
  notes: string[];
}

export type IResumeJSONSkill = string;

export interface IResumeJSONAchievement {
  issuer: string;
  name: string;
}

export interface IResumeJSON {
  intro: string;
  experiences: IResumeJSONExperience[];
  educations: IResumeJSONEducation[];
  skills: IResumeJSONSkill[];

  achievements: IResumeJSONAchievement[];
  explanation?: string;
  coverLetter?: string;
  tips?: string[];
}

export const enum ResumeStatus {
  New = 'new',
  WebsiteParsed = 'websiteparsed',
  Preparing = 'preparing',
  Generating = 'generating',
  CoverLetter = 'coverletter',
  Ok = 'ok',
  Error = 'error',
  InvalidURL = 'invalidurl',
}

export enum ResumeLanguage {
  en = '🇬🇧 English',
  de = '🇩🇪 Deutsch',
  fr = '🇫🇷 Français',
  es = '🇪🇸 Español',
  it = '🇮🇹 Italiano',
}

export type ResumeLanguageCode = keyof typeof ResumeLanguage;

export const resumeLanguageCode = new Map<string, ResumeLanguage>(
  Object.values(ResumeLanguage).map(
    (memberValue) => [memberValue, memberValue] as const,
  ),
);

export const MAX_JOB_AD_LENGTH = 16_000;

export enum JobAdType {
  URL = 'url',
  Text = 'text',
}

export const ResumeStyles = [
  'professional',
  'formal',
  'casual',
  'playful',
] as const;

export interface IResumeHistory {
  id: string;
  date: Date;
  tag?: string;
  notes?: string;
  resumeId: string;
}

export type ResumeId = string;

export interface IResume {
  id: ResumeId;
  title?: string;
  funkiness: number;
  info?: string;
  language?: ResumeLanguageCode;
  style?: string;
  jobAdType?: JobAdType;
  ad?: string;
  jobAdUrl?: string;
  isPaid: boolean;
  history?: IResumeHistory[];
  status: ResumeStatus;
  suggestion?: IResumeJSON;
  createdAt: string;
  updatedAt?: string;
}

export type IResumeWithSuggestion = IResume & { suggestion: IResumeJSON };

export function splitTitleText(resume: IResume): {
  label?: string;
  description?: string;
} {
  const titleParts = resume.title?.split(',') || [];
  const label =
    resume.status === ResumeStatus.Ok
      ? titleParts.length > 1
        ? titleParts[1]
        : resume.title
      : undefined;

  const description =
    resume.status === ResumeStatus.Ok
      ? titleParts.length > 1
        ? titleParts[0]
        : undefined
      : undefined;

  return { label, description };
}
