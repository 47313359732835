import {
  Center,
  Container,
  Grid,
  Group,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { motion, Variants } from 'framer-motion';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Bowl } from '../../components/Bowl/Bowl';
import { CreateResumeButton } from '../../components/CreateResumeButton/CreateResumeButton';
import { useResumesQuery } from '../../queries/ResumeQueries';
import { useUserQuery } from '../../models/User';
import AnalyzerCard from './AnalyzerCard';
import ApplicationStatusCard from './ApplicationStatusCard';
import OnboardingModal from './OnboardingModal';
import ProfileStatusCard from './ProfileStatusCard';
import RecommendationCard from './RecommendationCard';
import SubscribeCard from './SubscribeCard';

const easing = [0.6, -0.05, 0.01, 0.99];

const fadeInUp: Variants = {
  initial: {
    y: 100,
    opacity: 0,
    transition: { duration: 1.0, ease: easing },
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 1.0,
      ease: easing,
    },
  },
};

const fadeInDown: Variants = {
  initial: {
    y: -100,
    opacity: 0,
    transition: { duration: 1.0, ease: easing },
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 1.0,
      ease: easing,
    },
  },
};

const fadeInLeft: Variants = {
  initial: {
    x: -200,
    opacity: 0,
    transition: { duration: 1.0, ease: easing },
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1.0,
      ease: easing,
    },
  },
};

const fadeInRight: Variants = {
  initial: {
    x: 200,
    opacity: 0,
    transition: { duration: 1.0, ease: easing },
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1.0,
      ease: easing,
    },
  },
  exit: {
    x: 0,
    opacity: 1,
  },
};

const Home: React.FC = () => {
  const { t } = useTranslation();
  const { data: resumes } = useResumesQuery();
  const { data: user } = useUserQuery();
  const theme = useMantineTheme();
  const smallScreen = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  const getTimeBasedGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) return t('greetings.morning');
    if (hour < 18) return t('greetings.afternoon');
    return t('greetings.evening');
  };

  return (
    <Container size="xl">
      <Bowl title={t('home.pageTitle')} />
      <Stack gap="xl">
        <motion.div initial="initial" animate="animate" variants={fadeInDown}>
          <Stack gap="xs" align="center" mt="xl">
            <Text size="xl" c="dimmed" fw={500}>
              {getTimeBasedGreeting()}
            </Text>
            <Title order={1} c={theme.primaryColor}>
              {user?.profile?.firstName
                ? t('welcomeNamed', { name: user.profile.firstName })
                : t('welcomeHuman')}
            </Title>
            <Text size="lg" c="dimmed" ta="center" maw={600} mt="sm">
              {t('home.subtitle')}
            </Text>
          </Stack>
        </motion.div>

        {smallScreen && (
          <Center my="lg">
            <CreateResumeButton />
          </Center>
        )}

        <Grid gutter={{ base: 'md', sm: 'lg' }} align="stretch">
          {Array.isArray(resumes) && resumes.length === 0 && (
            <Grid.Col span={12}>
              <motion.div
                initial="initial"
                animate="animate"
                variants={fadeInDown}
                style={{ height: '100%' }}
              >
                <RecommendationCard />
              </motion.div>
            </Grid.Col>
          )}
          <Grid.Col span={{ base: 12, md: 6 }}>
            <motion.div
              initial="initial"
              animate="animate"
              variants={fadeInLeft}
              style={{ height: '100%' }}
            >
              <ProfileStatusCard />
            </motion.div>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <motion.div
              initial="initial"
              animate="animate"
              exit="exit"
              variants={fadeInRight}
              style={{ height: '100%' }}
            >
              <ApplicationStatusCard />
            </motion.div>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <motion.div
              initial="initial"
              animate="animate"
              variants={fadeInUp}
              style={{ height: '100%' }}
            >
              <SubscribeCard />
            </motion.div>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <motion.div
              initial="initial"
              animate="animate"
              variants={fadeInUp}
              style={{ height: '100%' }}
            >
              <AnalyzerCard />
            </motion.div>
          </Grid.Col>
        </Grid>

        <motion.div initial="initial" animate="animate" variants={fadeInUp}>
          <Group justify="center" mt="xl">
            <CreateResumeButton />
          </Group>
        </motion.div>
      </Stack>
      <OnboardingModal />
    </Container>
  );
};

export default Home;
