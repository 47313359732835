import { IUser } from '../models/User';
import Api from '../Api';
import { getLogger } from '../services/Logger';
import { ApiRoutes } from '../ApiRoutes';

const log = getLogger('AchievementsService');

export interface IAchievementDto {
  key: string;
  name: string;
  description?: string;
  icon?: string;
  isHidden: boolean;
  points: number;
  requiredProgress: number;
}

export interface IUserAchievementDto {
  currentProgress: number;
  isCompleted: boolean;
  completedAt?: Date;
}

export interface IAchievementEntity {
  id: string;
  key: string;
  name: string;
  description?: string;
  icon?: string;
  isHidden: boolean;
  points: number;
  requiredProgress: number;
  createdAt: string;
  updatedAt: string;
  criteria?: Record<string, unknown>;
}

interface IAchievementsResponse {
  achievements: IAchievementEntity[];
}

export interface IUserAchievementEntity {
  user: IUser;
  achievement: IAchievementEntity;
  currentProgress: number;
  isCompleted: boolean;
  completedAt?: Date;
}

interface IUserAchievementsResponse {
  achievements: IUserAchievementEntity[];
}

interface ILatestAchievementResponse {
  timestamp: string;
}

class AchievementsService {
  private static instance: AchievementsService;

  public static getInstance(): AchievementsService {
    if (!AchievementsService.instance) {
      AchievementsService.instance = new AchievementsService();
    }
    return AchievementsService.instance;
  }

  async getAllAchievements(): Promise<IAchievementEntity[]> {
    try {
      const response = await Api.ky
        .get(ApiRoutes.Achievements)
        .json<IAchievementsResponse>();
      return response.achievements;
    } catch (error) {
      log.error('Failed to fetch achievements:', error);
      throw new Error('Failed to fetch achievements');
    }
  }

  async getUserAchievements(): Promise<IUserAchievementEntity[]> {
    if (!Api.getToken()) {
      return [];
    }
    try {
      const response = await Api.ky
        .get(ApiRoutes.UserAchievements)
        .json<IUserAchievementsResponse>();
      return response.achievements;
    } catch (error) {
      log.error('Failed to fetch user achievements:', error);
      throw new Error('Failed to fetch user achievements');
    }
  }

  async getLatestAchievementTimestamp(): Promise<string | null> {
    if (!Api.getToken()) {
      return null;
    }
    try {
      const response = await Api.ky
        .get(`${ApiRoutes.UserAchievements}/latest`)
        .json<ILatestAchievementResponse>();
      return response.timestamp;
    } catch (error) {
      log.error('Failed to fetch latest achievement timestamp:', error);
      throw new Error('Failed to fetch latest achievement timestamp');
    }
  }
}

export default AchievementsService.getInstance();
