import { useMutation } from '@tanstack/react-query';
import Api from '../Api';
import { ApiRoutes } from '../ApiRoutes';

interface SuggestionResponse {
  suggestion: string;
}

interface SuggestionRequest {
  currentText: string;
  sectionId?: string;
  cursorPosition: number;
}

export const useTimelineSuggestionMutation = () => {
  return useMutation<SuggestionResponse, Error, SuggestionRequest>({
    mutationFn: async ({ currentText, sectionId, cursorPosition }) => {
      return Api.ky
        .post(`${ApiRoutes.Suggestions}/timeline`, {
          json: { currentText, sectionId, cursorPosition },
        })
        .json<SuggestionResponse>();
    },
  });
};
