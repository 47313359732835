import {
  AppShell,
  Box,
  Container,
  Group,
  Paper,
  Stack,
  Tabs,
  Text,
  Title,
  rem,
  useMantineTheme,
  useMantineColorScheme,
  MantineProvider,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { motion, AnimatePresence } from 'framer-motion';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { AppRouteURL } from '../../AppRouteURL';
import { Logo } from '../Logo/Logo';
import { LoginFooter } from '../LoginFooter/LoginFooter';
import { AuthLogin } from './AuthLogin';
import { AuthSignup } from './AuthSignup';
import loginIllustration from './assets/auth-login.svg';
import signupIllustration from './assets/auth-signup.svg';
import { BackgroundIllustration } from './assets/BackgroundIllustration';
import classes from './AuthPage.module.css';

const slideAnimation = {
  initial: { x: 20, opacity: 0 },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      delay: 0.1,
      type: 'spring',
      stiffness: 400,
      damping: 25,
      opacity: { duration: 0.3, delay: 0.1 },
    },
  },
  exit: {
    x: -20,
    opacity: 0,
    transition: {
      duration: 0.2,
      ease: 'easeInOut',
    },
  },
};

const heroAnimation = {
  initial: { opacity: 0, y: 20 },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      stiffness: 400,
      damping: 25,
      opacity: { duration: 0.3 },
    },
  },
  exit: {
    opacity: 0,
    y: -20,
    transition: {
      type: 'spring',
      stiffness: 400,
      damping: 25,
      opacity: { duration: 0.2 },
    },
  },
};

interface AuthPageProps {
  defaultTab?: 'login' | 'signup';
}

export const AuthPage: React.FC<AuthPageProps> = ({ defaultTab = 'login' }) => {
  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<'login' | 'signup'>(defaultTab);

  // Update tab when URL changes
  useEffect(() => {
    const tab = location.pathname === AppRouteURL.signup ? 'signup' : 'login';
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }, [location, activeTab]);

  const handleTabChange = (value: string | null) => {
    if (value) {
      const path = value === 'login' ? AppRouteURL.login : AppRouteURL.signup;
      if (location.pathname !== path) {
        void navigate(path, { replace: true });
      }
    }
  };

  return (
    <MantineProvider forceColorScheme="light">
      <AppShell
        padding="md"
        styles={{
          main: {
            background: 'transparent',
            position: 'relative',
            overflow: 'hidden',
            minHeight: '100vh',
            paddingBottom: rem(40),
          },
        }}
      >
        <BackgroundIllustration activeTab={activeTab} />

        <Container size="xl" h="100%" className={classes.container}>
          <Group
            justify="space-between"
            align="stretch"
            h="100%"
            w="100%"
            gap={80}
            wrap="nowrap"
          >
            {/* Left side - Auth forms */}
            <Box w={isMobile ? '100%' : '50%'}>
              <Stack gap="xl">
                <Logo type="Banner" h={isMobile ? 48 : 72} />

                <Paper
                  radius="md"
                  className={classes.formContainer}
                  styles={(theme) => ({
                    root: {
                      backgroundColor:
                        colorScheme === 'dark'
                          ? `rgba(${theme.colors.dark[7]}, 0.65)`
                          : 'rgba(255, 255, 255, 0.65)',
                      backdropFilter: 'blur(20px)',
                      border: `${rem(1)} solid ${
                        theme.colors.dark[colorScheme === 'dark' ? 5 : 1]
                      }`,
                    },
                  })}
                >
                  <Stack>
                    <Title order={2} fw={500}>
                      {t(
                        activeTab === 'login'
                          ? 'auth.welcome'
                          : 'auth.welcomeSignup',
                      )}
                    </Title>

                    <Text c="dimmed" size="sm">
                      {t(
                        activeTab === 'login'
                          ? 'auth.subtitle'
                          : 'auth.subtitleSignup',
                      )}
                    </Text>

                    <Tabs
                      value={activeTab}
                      onChange={handleTabChange}
                      variant="pills"
                      classNames={{
                        tab: classes.tab,
                        list: classes.tabList,
                        panel: classes.tabPanel,
                      }}
                    >
                      <Tabs.List grow>
                        <Tabs.Tab
                          value="login"
                          fw={500}
                          bg={activeTab === 'login' ? 'indigo.7' : 'gray.2'}
                        >
                          {t('auth.login')}
                        </Tabs.Tab>
                        <Tabs.Tab
                          value="signup"
                          fw={500}
                          bg={activeTab === 'signup' ? 'indigo.7' : 'gray.2'}
                        >
                          {t('auth.signup')}
                        </Tabs.Tab>
                      </Tabs.List>

                      <AnimatePresence mode="wait">
                        {activeTab === 'login' && (
                          <Tabs.Panel value="login" pt="xl" key="login-panel">
                            <motion.div
                              variants={slideAnimation}
                              initial="initial"
                              animate="animate"
                              exit="exit"
                              key="login-form"
                            >
                              <AuthLogin />
                            </motion.div>
                          </Tabs.Panel>
                        )}
                        {activeTab === 'signup' && (
                          <Tabs.Panel value="signup" pt="xl" key="signup-panel">
                            <motion.div
                              variants={slideAnimation}
                              initial="initial"
                              animate="animate"
                              exit="exit"
                              key="signup-form"
                            >
                              <AuthSignup />
                            </motion.div>
                          </Tabs.Panel>
                        )}
                      </AnimatePresence>
                    </Tabs>
                  </Stack>
                </Paper>
              </Stack>
            </Box>

            {/* Right side - Decorative/Branding (hidden on mobile) */}
            {!isMobile && (
              <Box className={classes.rightSection}>
                <AnimatePresence mode="wait">
                  <motion.div
                    key={activeTab}
                    variants={heroAnimation}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                  >
                    <Stack gap="xl" align="center" justify="center" h="100%">
                      <Title order={1} className={classes.heroTitle}>
                        {t(
                          activeTab === 'login'
                            ? 'auth.heroTitle'
                            : 'auth.heroTitleSignup',
                        )}
                      </Title>
                      <Text className={classes.heroSubtitle}>
                        {t(
                          activeTab === 'login'
                            ? 'auth.heroSubtitle'
                            : 'auth.heroSubtitleSignup',
                        )}
                      </Text>
                      <img
                        src={
                          activeTab === 'login'
                            ? loginIllustration
                            : signupIllustration
                        }
                        alt=""
                        className={classes.heroImage}
                      />
                    </Stack>
                  </motion.div>
                </AnimatePresence>
              </Box>
            )}
          </Group>
        </Container>

        <Box
          style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            height: rem(40),
          }}
        >
          <LoginFooter />
        </Box>
      </AppShell>
    </MantineProvider>
  );
};
