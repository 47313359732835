import {
  Card,
  Loader,
  Progress,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { motion } from 'framer-motion';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ResumeStatus } from '../../../../models/Resume';

interface LoadingPreviewProps {
  status?: ResumeStatus;
}

export const LoadingPreview: React.FC<LoadingPreviewProps> = ({ status }) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();

  return (
    <Stack gap="xl" align="center" py="xl">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.4, ease: [0.4, 0, 0.2, 1] }}
      >
        <Stack gap="xs" align="center" maw={600}>
          <Title order={2} ta="center" style={{ color: theme.colors.blue[7] }}>
            {t('create.wizard.preview.loadingTitle')}
          </Title>
          <Text size="lg" c="dimmed" ta="center" maw={600}>
            {t('create.wizard.preview.loadingDescription')}
          </Text>
        </Stack>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.8, ease: [0.4, 0, 0.2, 1] }}
      >
        <Stack gap="xl" align="center">
          <Card
            padding="xl"
            radius="lg"
            style={{
              backgroundColor: theme.colors.blue[0],
              border: `1px solid ${theme.colors.blue[2]}`,
              width: '100%',
              maxWidth: 600,
            }}
          >
            <Stack gap="lg" align="center">
              <Loader size="xl" type="bars" />
              <Text size="lg" fw={500} ta="center" c="blue.7">
                {t('create.wizard.preview.analyzingWebsite')}
              </Text>
              <Progress
                value={status === ResumeStatus.Preparing ? 75 : 25}
                size="xl"
                radius="xl"
                animated
                striped
                style={{ width: '100%' }}
              />
            </Stack>
          </Card>
        </Stack>
      </motion.div>
    </Stack>
  );
};
