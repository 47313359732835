import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useLatestAchievementQuery,
  useUserAchievementsQuery,
} from '../queries/AchievementQueries';
import { getLogger } from '../services/Logger';
import { showAchievementNotification } from '../components/AchievementNotification/AchievementNotification';

const log = getLogger('AchievementNotifications');

export const useAchievementNotifications = (pollingInterval = 30000) => {
  const { t } = useTranslation();
  const lastKnownTimestamp = useRef<string | null>(null);

  // Poll the latest achievement timestamp
  const { data: latestTimestamp } = useLatestAchievementQuery({
    refetchInterval: pollingInterval,
  });

  // Query achievements but don't enable by default
  const { refetch: refetchAchievements } = useUserAchievementsQuery({
    enabled: false,
  });

  useEffect(() => {
    // Skip if no timestamp or unchanged
    if (!latestTimestamp || latestTimestamp === lastKnownTimestamp.current) {
      return;
    }

    // Force fetch latest achievements
    refetchAchievements()
      .then(({ data: achievements }) => {
        if (!achievements) return;

        // Store the current timestamp before we update it
        const previousTimestamp = lastKnownTimestamp.current;

        // Show notifications for newly completed achievements
        achievements
          .filter(
            (a) =>
              a.isCompleted &&
              a.completedAt &&
              previousTimestamp &&
              new Date(a.completedAt) > new Date(previousTimestamp),
          )
          .forEach((achievement) => {
            showAchievementNotification(achievement, t);
          });

        // Update last known timestamp only after we've checked for new achievements
        lastKnownTimestamp.current = latestTimestamp;
      })
      .catch((error) => {
        log.error('Failed to fetch achievements:', error);
      });
  }, [latestTimestamp, refetchAchievements, t]);
};
