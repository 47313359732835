import { ThemeIcon, Tooltip, rem } from '@mantine/core';
import {
  IconAlertTriangle,
  IconCheck,
  IconHandClick,
  IconLoader2,
  IconRefresh,
} from '@tabler/icons-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ResumeStatus } from '../../models/Resume';

interface Props {
  status: ResumeStatus;
  size?: number;
}

export const ResumeStatusIcon: React.FC<Props> = ({ status, size = 16 }) => {
  const { t } = useTranslation();

  const iconProps = {
    style: { width: rem(size * 0.75), height: rem(size * 0.75) },
  };

  switch (status) {
    case ResumeStatus.Ok:
      return (
        <Tooltip label={t('applications.status.ready')} position="right">
          <ThemeIcon size={size} radius="xl" color="green" variant="light">
            <IconCheck {...iconProps} />
          </ThemeIcon>
        </Tooltip>
      );

    case ResumeStatus.Error:
      return (
        <Tooltip label={t('applications.status.error')} position="right">
          <ThemeIcon size={size} radius="xl" color="red" variant="light">
            <IconAlertTriangle {...iconProps} />
          </ThemeIcon>
        </Tooltip>
      );

    case ResumeStatus.New:
      return (
        <Tooltip label={t('applications.status.new')} position="right">
          <ThemeIcon size={size} radius="xl" color="gray" variant="light">
            <IconHandClick {...iconProps} />
          </ThemeIcon>
        </Tooltip>
      );

    case ResumeStatus.Preparing:
      return (
        <Tooltip label={t('applications.status.preparing')} position="right">
          <ThemeIcon size={size} radius="xl" color="blue" variant="light">
            <IconLoader2 {...iconProps} className="mantine-spin" />
          </ThemeIcon>
        </Tooltip>
      );

    case ResumeStatus.Generating:
      return (
        <Tooltip label={t('applications.status.generating')} position="right">
          <ThemeIcon size={size} radius="xl" color="blue" variant="light">
            <IconRefresh {...iconProps} className="mantine-spin" />
          </ThemeIcon>
        </Tooltip>
      );

    case ResumeStatus.WebsiteParsed:
      return (
        <Tooltip
          label={t('applications.status.websiteParsed')}
          position="right"
        >
          <ThemeIcon size={size} radius="xl" color="blue" variant="filled">
            <IconHandClick {...iconProps} />
          </ThemeIcon>
        </Tooltip>
      );

    case ResumeStatus.InvalidURL:
      return (
        <Tooltip label={t('applications.status.invalidUrl')} position="right">
          <ThemeIcon size={size} radius="xl" color="red" variant="light">
            <IconAlertTriangle {...iconProps} />
          </ThemeIcon>
        </Tooltip>
      );

    default:
      return null;
  }
};
