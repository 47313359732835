import React from 'react';
import { Card, Text, Progress, Badge, Stack } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import {
  IAchievementEntity,
  IUserAchievementEntity,
} from '../../services/Achievements';
import { showAchievementNotification } from '../AchievementNotification/AchievementNotification';
import styles from './AchievementCard.module.css';

interface AchievementCardProps {
  achievement: IAchievementEntity;
  userAchievement?: IUserAchievementEntity;
}

export const AchievementCard: React.FC<AchievementCardProps> = ({
  achievement,
  userAchievement,
}) => {
  const { t } = useTranslation();
  const progress = Math.min(
    userAchievement?.currentProgress ?? 0,
    achievement.requiredProgress,
  );
  const isCompleted = userAchievement?.isCompleted ?? false;
  const progressPercentage = (progress / achievement.requiredProgress) * 100;

  const handleClick = () => {
    if (userAchievement?.isCompleted) {
      showAchievementNotification(userAchievement, t);
    }
  };

  return (
    <Card
      className={styles.card}
      withBorder
      onClick={userAchievement?.isCompleted ? handleClick : undefined}
      style={{ cursor: userAchievement?.isCompleted ? 'pointer' : 'default' }}
    >
      <div className={styles.header}>
        <div className={styles.icon}>{achievement.icon}</div>
        <div className={styles.titleContainer}>
          <Text className={styles.title}>
            {t(`achievements.items.${achievement.key}.name`)}
          </Text>
          <Text size="sm" c="dimmed">
            {t('achievements.points', { points: achievement.points })}
          </Text>
        </div>
        {isCompleted && (
          <Badge color="green" variant="light">
            {t('achievements.completed_badge')}
          </Badge>
        )}
      </div>
      <Text className={styles.description} size="sm" c="dimmed">
        {t(`achievements.items.${achievement.key}.description`)}
      </Text>
      <Stack gap="xs" mt="xl">
        <Progress
          value={progressPercentage}
          color={isCompleted ? 'green' : 'blue'}
          size="sm"
        />
        <Text ta="center" size="sm" c="dimmed">
          {progress}/{achievement.requiredProgress}
        </Text>
      </Stack>
    </Card>
  );
};
