import { AppShell, Box, Group, Text, Title } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageMenu } from '../Layout/LanguageMenu';
import classes from './LoginFooter.module.css';

export const LoginFooter: React.FC = () => {
  const isMobile = useMediaQuery(`(max-width: 768px)`);
  const { t } = useTranslation();

  return (
    <AppShell.Footer className={classes.footer}>
      <Group
        justify="space-between"
        h="100%"
        px={isMobile ? 'sm' : 'xl'}
        wrap="nowrap"
      >
        <Title order={5} fz={12} className={classes.copyright}>
          {t('login.copyrightFooter', { year: new Date().getFullYear() })}
        </Title>
        <Box className={classes.languageWrapper}>
          <LanguageMenu size="sm" long={!isMobile} />
        </Box>
      </Group>
    </AppShell.Footer>
  );
};
