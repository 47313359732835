import {
  MantineProvider,
  useComputedColorScheme,
  useMantineColorScheme,
} from '@mantine/core';
import { useHotkeys } from '@mantine/hooks';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { AppRoutes } from './AppRoutes';
import { ProvideAuth } from './Auth';
import { Plausible } from './components/Plausible/Plausible';
import { DEVTOOLS_ENABLED, PLAUSIBLE_API, PLAUSIBLE_DOMAIN } from './Config';
import { useAchievementNotifications } from './hooks/useAchievementNotifications';

import '@fontsource-variable/spline-sans';
import '@fontsource-variable/outfit';

import '@mantine/core/styles/global.css';
import '@mantine/core/styles.css';
import '@mantine/carousel/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/dropzone/styles.css';
import '@mantine/notifications/styles.css';

import './global.css';
import Platform from './services/Platform';
import { queryClient } from './services/QueryClient';

const POLLING_INTERVAL = 15000;

const HotkeyManager: React.FC = () => {
  const { toggleColorScheme } = useMantineColorScheme();
  useHotkeys([
    [
      'mod+J',
      () => {
        toggleColorScheme();
      },
    ],
  ]);

  return null;
};

const ColorSchemeWatcher: React.FC = () => {
  const colorScheme = useComputedColorScheme('light');

  React.useEffect(() => {
    Platform.setColorScheme(colorScheme);
  }, [colorScheme]);

  return null;
};

const AchievementWatcher: React.FC = () => {
  useAchievementNotifications(POLLING_INTERVAL);
  return null;
};

function App() {
  const { i18n } = useTranslation();

  return (
    <HelmetProvider>
      {PLAUSIBLE_DOMAIN && PLAUSIBLE_API && (
        <Plausible domain={PLAUSIBLE_DOMAIN} apiHost={PLAUSIBLE_API} />
      )}
      <MantineProvider
        defaultColorScheme="auto"
        theme={{
          fontFamily: 'Spline Sans Variable, sans-serif',
          primaryColor: 'indigo',
          headings: {
            fontFamily: 'Outfit Variable, sans-serif',
          },
          defaultRadius: 'md',
        }}
      >
        <HotkeyManager />
        <ColorSchemeWatcher />
        <Notifications />
        <QueryClientProvider client={queryClient}>
          {DEVTOOLS_ENABLED && <ReactQueryDevtools initialIsOpen={false} />}
          <ProvideAuth>
            <ModalsProvider>
              <AchievementWatcher />
              <Helmet
                htmlAttributes={{
                  lang: i18n.language.substring(0, 2).toLowerCase() || 'en',
                }}
              />
              <AppRoutes />
            </ModalsProvider>
          </ProvideAuth>
        </QueryClientProvider>
      </MantineProvider>
    </HelmetProvider>
  );
}

export default App;
