import { useRef, useEffect, useState } from 'react';
import { Fireworks } from '@fireworks-js/react';
import type { FireworksHandlers } from '@fireworks-js/react';
import { useReward } from 'react-rewards';

type CelebrationType = 'fireworks' | 'confetti' | 'emoji' | 'balloons';

export const CelebrationEffect = () => {
  const ref = useRef<FireworksHandlers>(null);
  const [opacity, setOpacity] = useState(0.7);

  const { reward: confettiReward } = useReward('confettiReward', 'confetti', {
    lifetime: 800,
    spread: 100,
    startVelocity: 45,
    elementCount: 120,
    position: 'fixed',
    angle: 90,
    zIndex: 9999,
  });

  const { reward: emojiReward } = useReward('emojiReward', 'emoji', {
    lifetime: 1000,
    spread: 80,
    startVelocity: 35,
    elementCount: 20,
    elementSize: 40,
    position: 'fixed',
    angle: 90,
    emoji: ['🏆', '⭐', '✨', '🌟'],
    zIndex: 9999,
  });

  const { reward: balloonsReward } = useReward('balloonsReward', 'balloons', {
    lifetime: 1200,
    spread: 60,
    startVelocity: 8,
    elementCount: 20,
    elementSize: 30,
    position: 'fixed',
    angle: 90,
    zIndex: 9999,
  });

  useEffect(() => {
    const effects: CelebrationType[] = ['confetti', 'emoji', 'balloons'];
    const randomEffect = effects[Math.floor(Math.random() * effects.length)];

    // Start fireworks in all cases for extra flair
    ref.current?.start();

    // Play the random additional effect
    switch (randomEffect) {
      case 'confetti':
        confettiReward();
        break;
      case 'emoji':
        emojiReward();
        break;
      case 'balloons':
        balloonsReward();
        break;
    }

    // Start fading out fireworks after 2s
    const fadeTimer = setTimeout(() => {
      // Gradually reduce opacity
      const fadeInterval = setInterval(() => {
        setOpacity((prev) => {
          const newOpacity = prev - 0.05; // Slower fade
          if (newOpacity <= 0) {
            clearInterval(fadeInterval);
            return 0;
          }
          return newOpacity;
        });
      }, 100);

      // Reduce intensity
      ref.current?.updateOptions({
        intensity: 20,
        delay: {
          min: 30,
          max: 60,
        },
      });

      // Stop and clear after fade completes
      const stopTimer = setTimeout(() => {
        ref.current?.stop();
        ref.current?.clear();
      }, 1500);

      return () => {
        clearInterval(fadeInterval);
        clearTimeout(stopTimer);
      };
    }, 2000);

    return () => {
      clearTimeout(fadeTimer);
    };
  }, []);

  return (
    <>
      <span
        id="confettiReward"
        style={{
          position: 'fixed',
          bottom: '80px',
          left: '50%',
          transform: 'translateX(-50%)',
          width: 2,
          height: 2,
        }}
      />
      <span
        id="emojiReward"
        style={{
          position: 'fixed',
          bottom: '80px',
          left: '50%',
          transform: 'translateX(-50%)',
          width: 2,
          height: 2,
        }}
      />
      <span
        id="balloonsReward"
        style={{
          position: 'fixed',
          bottom: '80px',
          left: '50%',
          transform: 'translateX(-50%)',
          width: 2,
          height: 2,
        }}
      />
      {opacity > 0 && (
        <Fireworks
          ref={ref}
          options={{
            opacity,
            explosion: 8,
            intensity: 35,
            traceLength: 3,
            traceSpeed: 10,
            rocketsPoint: {
              min: 45,
              max: 85,
            },
            delay: {
              min: 15,
              max: 30,
            },
            brightness: {
              min: 50,
              max: 80,
            },
            decay: {
              min: 0.015,
              max: 0.03,
            },
            mouse: {
              click: false,
              move: false,
              max: 1,
            },
          }}
          style={{
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            position: 'fixed',
            pointerEvents: 'none',
            background: 'transparent',
            zIndex: 9999,
            transition: 'opacity 0.5s ease-out',
          }}
        />
      )}
    </>
  );
};
