import {
  Alert,
  Anchor,
  Button,
  Center,
  Container,
  Group,
  Loader,
  Paper,
  Stack,
  Table,
  Text,
  Title,
  Transition,
  rem,
} from '@mantine/core';
import {
  IconCirclePlus,
  IconInfoCircle,
  IconSearch,
} from '@tabler/icons-react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AppRouteURL } from '../../AppRouteURL';
import { Bowl } from '../../components/Bowl/Bowl';
import { isProfileEmpty } from '../../models/ProfileQueries';
import { useResumesQuery } from '../../queries/ResumeQueries';
import { ResumeTableRow } from './ResumeTableRow';

// const log = getLogger('ListResumesPage');

const EmptyProfileWarning: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Transition mounted={true} transition="fade" duration={400}>
      {(styles) => (
        <Paper withBorder p="xl" radius="md" shadow="sm" style={styles}>
          <Stack align="center" gap="md">
            <IconInfoCircle size={48} color="var(--mantine-color-blue-6)" />
            <Title order={3} ta="center">
              {t('applications.missingProfile')}
            </Title>
            <Text size="lg" ta="center" maw={500}>
              <Trans i18nKey="applications.missingProfileInfo">
                You need to
                <Anchor component={Link} to={AppRouteURL.profileTabBasic}>
                  update your profile
                </Anchor>
                before you can start creating applications!
              </Trans>
            </Text>
          </Stack>
        </Paper>
      )}
    </Transition>
  );
};

const NoResumesAlert: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Transition mounted={true} transition="fade" duration={400}>
      {(styles) => (
        <Paper withBorder p="xl" radius="md" shadow="sm" style={styles}>
          <Stack align="center" gap="md">
            <IconSearch size={48} color="var(--mantine-color-blue-6)" />
            <Title order={3} ta="center">
              {t('applications.noApplications')}
            </Title>
            <Text size="lg" ta="center" maw={500}>
              <Trans i18nKey="applications.noApplicationsInfo">
                You have not created any applications yet. Go ahead and
                <Anchor component={Link} to={AppRouteURL.resumes.create}>
                  create one
                </Anchor>
                !
              </Trans>
            </Text>
          </Stack>
        </Paper>
      )}
    </Transition>
  );
};

export const ListResumesPage: React.FC = () => {
  const profileEmpty = isProfileEmpty();
  const { t } = useTranslation();

  const { data: resumes, isPending } = useResumesQuery();

  const noResumes = !resumes || resumes.length === 0;

  const content = isPending ? (
    <Center p="xl">
      <Loader size="xl" variant="bars" />
    </Center>
  ) : profileEmpty ? (
    <EmptyProfileWarning />
  ) : noResumes ? (
    <NoResumesAlert />
  ) : (
    <Transition mounted={true} transition="fade" duration={400}>
      {(styles) => (
        <Paper withBorder radius="md" shadow="sm" style={styles}>
          <Table highlightOnHover striped>
            <Table.Thead>
              <Table.Tr>
                <Table.Th style={{ width: '60%' }}>
                  {t('applications.title')}
                </Table.Th>
                <Table.Th style={{ width: '25%' }}>
                  {t('applications.created')}
                </Table.Th>
                <Table.Th style={{ width: '15%', textAlign: 'right' }}>
                  {t('applications.actions')}
                </Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {resumes.map((resume) => (
                <ResumeTableRow key={resume.id} resume={resume} />
              ))}
            </Table.Tbody>
          </Table>
        </Paper>
      )}
    </Transition>
  );

  return (
    <Container size="lg">
      <Bowl title={t('applications.pageTitle')} />

      <Stack gap="xl">
        <Group justify="space-between" align="center">
          <Stack gap={4}>
            <Title order={2}>{t('applications.pageTitle')}</Title>
            {resumes && resumes.length > 0 && (
              <Text c="dimmed" size="sm">
                {t('applications.totalCount', { count: resumes.length })}
              </Text>
            )}
          </Stack>
          <Transition
            mounted={!profileEmpty}
            transition="slide-left"
            duration={400}
          >
            {(styles) => (
              <Button
                component={Link}
                to={AppRouteURL.resumes.create}
                variant="gradient"
                gradient={{ from: 'blue', to: 'violet' }}
                leftSection={
                  <IconCirclePlus style={{ width: rem(20), height: rem(20) }} />
                }
                size="md"
                style={styles}
              >
                {t('applications.newApplication')}
              </Button>
            )}
          </Transition>
        </Group>

        {content}
      </Stack>
    </Container>
  );
};
