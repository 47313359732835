import React from 'react';
import {
  Container,
  Grid,
  Title,
  Text,
  Skeleton,
  Progress,
} from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { Bowl } from '../../components/Bowl/Bowl';
import { AchievementCard } from '../../components/AchievementCard/AchievementCard';
import styles from './AchievementsPage.module.css';
import {
  useAchievementsQuery,
  useUserAchievementsQuery,
} from '../../queries/AchievementQueries';

export const AchievementsPage: React.FC = () => {
  const { t } = useTranslation();
  const {
    data: achievements = [],
    isLoading: isAchievementsLoading,
    error: achievementsError,
  } = useAchievementsQuery();
  const {
    data: userAchievements = [],
    isLoading: isUserAchievementsLoading,
    error: userAchievementsError,
  } = useUserAchievementsQuery();

  const getUserAchievement = (achievementKey: string) => {
    return userAchievements.find((ua) => ua.achievement.key === achievementKey);
  };

  const totalPoints = userAchievements.reduce((sum, ua) => {
    if (ua.isCompleted) {
      return sum + ua.achievement.points;
    }
    return sum;
  }, 0);

  const completedAchievements = userAchievements.filter(
    (ua) => ua.isCompleted,
  ).length;

  const totalAchievements = achievements.filter(
    (achievement) =>
      !achievement.isHidden || getUserAchievement(achievement.key),
  ).length;

  const completionPercentage = Math.round(
    (completedAchievements / totalAchievements) * 100,
  );

  const isLoading = isAchievementsLoading || isUserAchievementsLoading;
  const error = achievementsError || userAchievementsError;

  const sortedAchievements = React.useMemo(() => {
    return [...achievements].sort((a, b) => {
      const nameA = t(`achievements.items.${a.key}.name`) as string;
      const nameB = t(`achievements.items.${b.key}.name`) as string;
      return nameA.localeCompare(nameB);
    });
  }, [achievements, t]);

  return (
    <>
      <Bowl title={t('achievements.pageTitle')} />
      <Container size="lg">
        <div className={styles.header}>
          <Title order={2}>{t('achievements.pageTitle')}</Title>
        </div>

        {isLoading ? (
          <Skeleton height={200} radius="lg" mb="xl" />
        ) : (
          <div className={styles.progressOverview}>
            <div className={styles.backdrop} />
            <div className={styles.backdropEdge} />
            <Progress
              value={completionPercentage}
              size="xl"
              radius="xl"
              className={styles.progressBar}
              color="blue"
              striped
              animated
            />
            <div className={styles.progressGrid}>
              <div className={styles.statCard}>
                <div className={styles.statValue}>{totalPoints}</div>
                <div className={styles.statLabel}>
                  {t('achievements.totalPoints', { points: totalPoints })}
                </div>
              </div>
              <div className={styles.statCard}>
                <div className={styles.statValue}>{completionPercentage}%</div>
                <div className={styles.statLabel}>
                  {t('achievements.completionRate')}
                </div>
              </div>
              <div className={styles.statCard}>
                <div className={styles.statValue}>
                  {completedAchievements}/{totalAchievements}
                </div>
                <div className={styles.statLabel}>
                  {t('achievements.achievementsCompleted')}
                </div>
              </div>
            </div>
          </div>
        )}

        {error && (
          <Text c="red" mb="xl">
            {t('achievements.error.loading')}
          </Text>
        )}

        <Grid>
          {isLoading
            ? Array.from({ length: 6 }).map((_, index) => (
                <Grid.Col key={index} span={{ base: 12, sm: 6, md: 4 }}>
                  <Skeleton height={200} radius="md" />
                </Grid.Col>
              ))
            : sortedAchievements
                .filter(
                  (achievement) =>
                    !achievement.isHidden ||
                    getUserAchievement(achievement.key),
                )
                .map((achievement) => (
                  <Grid.Col
                    key={achievement.key}
                    span={{ base: 12, sm: 6, md: 4 }}
                  >
                    <AchievementCard
                      achievement={achievement}
                      userAchievement={getUserAchievement(achievement.key)}
                    />
                  </Grid.Col>
                ))}
        </Grid>
      </Container>
    </>
  );
};
