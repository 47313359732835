import {
  Card,
  Progress,
  Stack,
  Text,
  Title,
  useMantineTheme,
  Box,
  rem,
} from '@mantine/core';
import { IconRefresh } from '@tabler/icons-react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { AppRouteURL } from '../../../AppRouteURL';
import { ResumeStatus } from '../../../models/Resume';
import { useResumeQuery } from '../../../queries/ResumeQueries';
import { useWizard } from '../context/WizardContext';

const STATUS_PROGRESS: Record<ResumeStatus, number> = {
  [ResumeStatus.New]: 0,
  [ResumeStatus.Preparing]: 10,
  [ResumeStatus.WebsiteParsed]: 20,
  [ResumeStatus.Generating]: 50,
  [ResumeStatus.CoverLetter]: 80,
  [ResumeStatus.Ok]: 100,
  [ResumeStatus.Error]: 100,
  [ResumeStatus.InvalidURL]: 0,
};

const STATUS_MESSAGES: Record<ResumeStatus, string> = {
  [ResumeStatus.New]: 'create.wizard.generation.status.new',
  [ResumeStatus.Preparing]: 'create.wizard.generation.status.preparing',
  [ResumeStatus.WebsiteParsed]: 'create.wizard.generation.status.websiteParsed',
  [ResumeStatus.Generating]: 'create.wizard.generation.status.generating',
  [ResumeStatus.CoverLetter]: 'create.wizard.generation.status.coverLetter',
  [ResumeStatus.Ok]: 'create.wizard.generation.status.ok',
  [ResumeStatus.Error]: 'create.wizard.generation.status.error',
  [ResumeStatus.InvalidURL]: 'create.wizard.generation.status.invalidUrl',
};

const pulseAnimation = {
  scale: [1, 1.05, 1],
  opacity: [0.85, 1, 0.85],
  transition: {
    duration: 3,
    repeat: Infinity,
    ease: 'easeInOut',
  },
};

const floatAnimation = {
  y: [0, -6, 0],
  transition: {
    duration: 4,
    repeat: Infinity,
    ease: 'easeInOut',
  },
};

const orbitAnimation = {
  rotate: 360,
  transition: {
    duration: 20,
    repeat: Infinity,
    ease: 'linear',
  },
};

const particleAnimation = {
  opacity: [0, 1, 0],
  scale: [0.8, 1, 0.8],
  transition: {
    duration: 3,
    repeat: Infinity,
    ease: 'easeInOut',
    delay: Math.random() * 2,
  },
};

const spinAnimation = {
  rotate: -360,
  transition: {
    duration: 3,
    repeat: Infinity,
    ease: 'linear',
  },
};

export const GenerationStep: React.FC = () => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const {
    state: { generation },
  } = useWizard();

  const { data: resume } = useResumeQuery(generation.resumeId);
  const status = resume?.status ?? generation.status;
  const progress = STATUS_PROGRESS[status];
  const message = STATUS_MESSAGES[status];

  useEffect(() => {
    if (status === ResumeStatus.Ok && generation.resumeId) {
      void navigate(`${AppRouteURL.resumes.view}/${generation.resumeId}`);
    }
  }, [status, generation.resumeId, navigate]);

  if (status === ResumeStatus.Error) {
    return (
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.4, ease: [0.4, 0, 0.2, 1] }}
      >
        <Stack gap="xl" align="center" py="xl">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1, duration: 0.4 }}
          >
            <Title order={2} ta="center" style={{ color: theme.colors.red[7] }}>
              {t('create.wizard.generation.errorTitle')}
            </Title>
            <Text size="lg" c="dimmed" ta="center" maw={600}>
              {generation.error || t('create.wizard.generation.errorDefault')}
            </Text>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.2, duration: 0.4 }}
          >
            <Card
              withBorder
              padding="xl"
              radius="md"
              style={{
                backgroundColor: theme.colors.red[0],
                maxWidth: 600,
                width: '100%',
              }}
            >
              <Stack gap="md" align="center">
                <motion.div
                  initial={{ rotate: -180, opacity: 0 }}
                  animate={{ rotate: 0, opacity: 1 }}
                  transition={{ delay: 0.3, duration: 0.6, type: 'spring' }}
                >
                  <IconRefresh
                    size={48}
                    style={{ color: theme.colors.red[7] }}
                  />
                </motion.div>
                <Text ta="center" size="lg">
                  {t('create.wizard.generation.errorHelp')}
                </Text>
              </Stack>
            </Card>
          </motion.div>
        </Stack>
      </motion.div>
    );
  }

  return (
    <Box
      style={{
        position: 'relative',
        minHeight: '80vh',
        width: '100%',
        overflow: 'hidden',
        background: '#fff',
      }}
    >
      {/* Animated Background Elements */}
      <motion.div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: rem(800),
          height: rem(800),
          transform: 'translate(-50%, -50%)',
          opacity: 0.03,
        }}
        animate={orbitAnimation}
      >
        <svg width="100%" height="100%" viewBox="0 0 100 100">
          <circle
            cx="50"
            cy="50"
            r="48"
            fill="none"
            stroke="url(#orbit-gradient)"
            strokeWidth="0.5"
          />
          <defs>
            <linearGradient
              id="orbit-gradient"
              x1="0%"
              y1="0%"
              x2="100%"
              y2="100%"
            >
              <stop offset="0%" stopColor="#000428" />
              <stop offset="100%" stopColor="#001e45" />
            </linearGradient>
          </defs>
        </svg>
      </motion.div>

      {/* Particles */}
      {Array.from({ length: 12 }).map((_, i) => (
        <motion.div
          key={i}
          style={{
            position: 'absolute',
            top: `${Math.random() * 100}%`,
            left: `${Math.random() * 100}%`,
            width: rem(4),
            height: rem(4),
            borderRadius: '50%',
            background: 'rgba(0, 32, 96, 0.1)',
          }}
          animate={particleAnimation}
        />
      ))}

      {/* Content Overlay */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        style={{
          position: 'relative',
          zIndex: 1,
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '2rem',
        }}
      >
        <Stack
          gap="xl"
          align="center"
          style={{ width: '100%', maxWidth: rem(600) }}
        >
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3, duration: 0.8 }}
          >
            <Stack gap="xs" align="center">
              <Title
                order={2}
                ta="center"
                style={{
                  color: '#000428',
                  fontWeight: 600,
                  fontSize: rem(32),
                  letterSpacing: rem(-0.5),
                }}
              >
                {t('create.wizard.generation.title')}
              </Title>
              <Text
                size="lg"
                ta="center"
                style={{
                  color: 'rgba(0, 4, 40, 0.7)',
                  maxWidth: rem(480),
                  lineHeight: 1.6,
                }}
              >
                {t('create.wizard.generation.description')}
              </Text>
            </Stack>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.4, duration: 0.8 }}
          >
            <Card
              padding={rem(40)}
              radius="lg"
              style={{
                background: 'linear-gradient(135deg, #000428 0%, #001e45 100%)',
                backdropFilter: 'blur(16px)',
                border: '1px solid rgba(0, 32, 96, 0.1)',
                width: '100%',
                boxShadow: '0 8px 32px rgba(0, 32, 96, 0.1)',
              }}
            >
              <Stack gap="xl" align="center">
                <motion.div
                  style={{
                    position: 'relative',
                    padding: rem(32),
                    width: '100%',
                  }}
                >
                  <Stack gap={rem(32)} align="center">
                    <motion.div
                      style={{
                        background: 'rgba(255, 255, 255, 0.03)',
                        borderRadius: '50%',
                        padding: rem(24),
                        border: '1px solid rgba(255, 255, 255, 0.1)',
                      }}
                      animate={{
                        ...pulseAnimation,
                        ...floatAnimation,
                      }}
                    >
                      <motion.div
                        animate={spinAnimation}
                        style={{ display: 'flex' }}
                      >
                        <IconRefresh
                          size={rem(48)}
                          style={{
                            color: '#fff',
                            filter:
                              'drop-shadow(0 0 12px rgba(255, 255, 255, 0.2))',
                          }}
                        />
                      </motion.div>
                    </motion.div>

                    <Text
                      size="lg"
                      fw={500}
                      style={{
                        color: '#fff',
                        textAlign: 'center',
                        letterSpacing: rem(0.2),
                      }}
                    >
                      {t(message)}
                    </Text>

                    <motion.div
                      initial={{ opacity: 0, scaleX: 0 }}
                      animate={{ opacity: 1, scaleX: 1 }}
                      transition={{
                        delay: 0.6,
                        duration: 1,
                        ease: 'circOut',
                      }}
                      style={{ width: '100%' }}
                    >
                      <Progress
                        value={progress}
                        size="lg"
                        radius="xl"
                        animated
                        styles={() => ({
                          root: {
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                          },
                          section: {
                            background:
                              'linear-gradient(90deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.9))',
                            boxShadow: '0 0 20px rgba(255, 255, 255, 0.2)',
                            transition: 'width 0.5s ease-out',
                          },
                        })}
                      />
                    </motion.div>
                  </Stack>
                </motion.div>
              </Stack>
            </Card>
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.8, duration: 0.8 }}
          >
            <Text
              size="sm"
              ta="center"
              style={{
                color: 'rgba(0, 4, 40, 0.5)',
                maxWidth: rem(400),
                margin: '0 auto',
              }}
            >
              {t('create.wizard.generation.patience')}
            </Text>
          </motion.div>
        </Stack>
      </motion.div>
    </Box>
  );
};
