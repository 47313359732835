import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import AchievementsService from '../services/Achievements';
import { getLogger } from '../services/Logger';
import { IUserAchievementEntity } from '../services/Achievements';

const log = getLogger('AchievementQueries');

export const achievementKeys = {
  all: ['achievements'] as const,
  list: () => [...achievementKeys.all, 'list'] as const,
  my: () => [...achievementKeys.all, 'my'] as const,
  latest: () => [...achievementKeys.all, 'latest'] as const,
};

export const useAchievementsQuery = () => {
  return useQuery({
    queryKey: achievementKeys.list(),
    queryFn: async () => {
      try {
        return await AchievementsService.getAllAchievements();
      } catch (err) {
        const errorMessage = err instanceof Error ? err.message : String(err);
        log.error('Failed to fetch achievements:', errorMessage);
        throw err;
      }
    },
  });
};

export const useUserAchievementsQuery = (
  options?: Partial<UseQueryOptions<IUserAchievementEntity[]>>,
) => {
  return useQuery({
    queryKey: achievementKeys.my(),
    queryFn: async () => {
      try {
        return await AchievementsService.getUserAchievements();
      } catch (err) {
        const errorMessage = err instanceof Error ? err.message : String(err);
        log.error('Failed to fetch user achievements:', errorMessage);
        throw err;
      }
    },
    ...options,
  });
};

export const useLatestAchievementQuery = (
  options?: Partial<UseQueryOptions<string | null>>,
) => {
  return useQuery({
    queryKey: achievementKeys.latest(),
    queryFn: async () => {
      try {
        return await AchievementsService.getLatestAchievementTimestamp();
      } catch (err) {
        const errorMessage = err instanceof Error ? err.message : String(err);
        log.error('Failed to fetch latest achievement:', errorMessage);
        throw err;
      }
    },
    ...options,
  });
};
