import { Paper, Stack, Text, Title, ThemeIcon } from '@mantine/core';
import { IconRobot } from '@tabler/icons-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRouteURL } from '../../AppRouteURL';
import { motion } from 'framer-motion';

import classes from './HomeCard.module.css';

const AnalyzerCard: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => {
    void navigate(AppRouteURL.analyze);
  };

  return (
    <motion.div
      layout
      style={{ height: '100%' }}
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
    >
      <Paper
        shadow="lg"
        radius="xl"
        p="xl"
        onClick={handleClick}
        className={classes.homeCard}
      >
        <div className={classes.homeCardWrapper}>
          <Stack gap="md">
            <Title order={5}>{t('header.analyzer')}</Title>
            <div className={classes.homeCardContent}>
              <Stack gap="xl" align="center">
                <ThemeIcon
                  size="xl"
                  radius="xl"
                  variant="gradient"
                  gradient={{ from: 'blue', to: 'cyan' }}
                >
                  <IconRobot style={{ width: '70%', height: '70%' }} />
                </ThemeIcon>
                <Stack gap="md" align="center">
                  <Title order={3} ta="center">
                    {t('home.analyzer.title')}
                  </Title>
                  <Text size="md" c="dimmed" ta="center">
                    {t('home.analyzer.description')}
                  </Text>
                  <Text
                    component="span"
                    size="sm"
                    c="blue"
                    fw={500}
                    style={{ cursor: 'pointer' }}
                  >
                    {t('home.analyzer.start')}
                  </Text>
                </Stack>
              </Stack>
            </div>
          </Stack>
        </div>
      </Paper>
    </motion.div>
  );
};

export default AnalyzerCard;
